import React from 'react'


const Wrapper = props => {

    const icon = props.config.template.includes("arc-3") ? <i className="arc3-icon">star</i> : <i className="icon arc rating-star medium"></i>
   
    const closeIcon = props.config.template.includes("arc-3") 
    ?  <div data-tam="close_pop_up" className="xsellfrontend-moreinfo-close-arc-3" onClick={() => props.togglePopUp()}> <i className="arc3-icon arc3-icon--color-brand-cyan">close</i> </div> 
    :  <div data-tam="close_pop_up" className="xsellfrontend-moreinfo-close" onClick={() => props.togglePopUp()}><i className="icon form-error extra-small"></i></div>
    
    return (
        <div className="xsellfrontend-moreinfo-wrapper">
            <div className="xsellfrontend-moreinfo" id="xsellfrontend-moreinfo">
                <div className="xsellfrontend-moreinfo-popup-overlay" onClick={() => props.togglePopUp()}></div>
                {closeIcon}
                {props.children}

            </div>
        </div>
    )
}


export default Wrapper
