import React, { Component } from 'react'
import locale from '../../../app/locale'
import {If} from '../../../app/helpers'
import ImageContainerSmall from '../../app/ImageContainerSmall'
import DiscountRibbons from '../../app/DiscountRibbons'
import DiscountRibbonsSmall from '../../app/DiscountRibbonsSmall'
import ProductCarousel from '../../app/ProductCarousel'
import PreviewCarousel from '../../app/PreviewCarousel'
import DiscountRibbonsStore from '../../../app/DiscountRibbonsStyle'
import Button from '../../app/Button'
import templateHelpers from '../templateHelpers'
import Popup from '../../popup/Popup'
import AddToCart from '../../app/addToCart/AddToCart'
import { Button as ArcButton } from "@albelli/arc-3-react/lib/Button";
import loaderGreen from '../../../../graphic/xsellLoaderGreen.svg'
import check from '../../../../graphic/xsellCheck.png'
import layoutHelpers from '../../../app/layoutHelpers'

@templateHelpers
class Portrait extends Component{
    constructor(props){
        super(props)
        this.showCarousel = this.props.config.showCarousel
        this.hasImagesForCarousel = true
    }

    isMobileView() {
        return window.innerWidth <= 600
    }
    
    render(){
        const { config, product, productStyle, helpers, moduleIndex, index, guid, productActions, hideAllCarousels, showCheckOnOrderbutton, dataTamProductType } = this.props
        let addTocartStatus = product.addToCartRequest.status

        if(product.hasOwnProperty("carouselProducts") && product.carouselProducts.status == "complete" && !product.carouselProducts.data) {
            this.showCarousel = false
        }

        const text = product.carouselType !== "preview" ? locale[config.language]["bottomTextMoreInfo"] : locale[config.language]["moreInfoDefaultText"]

        const addToCartButtonProps = {
            secondary: true,
            className: "xsellfrontend-standard-portrait-add-to-basket-button-large"                  
        }
  
        return (
            <div data-tam='xsellfrontend-wrapper' data-tam-product-type={dataTamProductType} style={productStyle} className={"xsellfrontend-standard-portrait-wrapper"}>    
                <If cond={this.isMobileView()}>
                    <span className="xsellfrontend-standard-portrait-left-text-container-header" data-tam='xsellfrontend-text-header'>
                            <div>
                                <span data-tam='xsellfrontend-product-name'> {product.productTranslation.name} </span> 
                                <span className="xsellfrontend-standard-portrait-left-text-container-header-description" data-tam='xsellfrontend-product-description'>{product.productTranslation.description}</span>        
                            </div>
                    </span>
                </If>

                <If cond={!this.isMobileView()}>
                    <DiscountRibbons index={index} store={DiscountRibbonsStore} product={product} config={config} cssClass="xsellfrontend-discount-ribbon-large" />
                </If>

                <ImageContainerSmall 
                    updateOverlay={helpers.updateOverlay} 
                    overlay={helpers.overlay} 
                    product={product} 
                    togglePopUp={() => helpers.togglePopUp()} 
                    status={addTocartStatus} 
                    language={config.language}
                    cssClass={"xsellfrontend-standard-portrait-image-container"}
                />

                {helpers.showPopUp &&  <Popup 
                    config={config} 
                    showCarousel={this.showCarousel}
                    guid={guid}
                    product={product}
                    index={index}
                    moduleIndex={moduleIndex}
                    togglePopUp={() => helpers.togglePopUp()} 
                    getCarouselImageBoxes={helpers.getCarouselImageBoxes}
                    productActions={productActions} 
                    maxNumberOfProducts={config.maxImagesInSmallCarousel}
                    showCheckOnOrderbutton={showCheckOnOrderbutton}
                /> }
                             
                <div className={product.price.discountAutoApply ? "xsellfrontend-standard-portrait-text-container": "xsellfrontend-standard-portrait-text-container-discountNotAutoApply"} data-tam="xsell_products">
                    <If cond={!hideAllCarousels && product.usedImageList && product.carouselType == "product" && !this.isMobileView()}>
                        <ProductCarousel 
                            showCarousel={this.showCarousel} 
                            productActions={productActions} 
                            index={index} 
                            moduleIndex={moduleIndex} 
                            guid={guid}
                            config={config} 
                            product={product} 
                            getCarouselImageBoxes={helpers.getCarouselImageBoxes}
                            maxNumberOfProducts={config.maxImagesInSmallCarousel}
                            thumbCssClass="xsellfrontend-viewmore-images-70"
                            wrapperCssClass="xsellfrontend-product-carousel-main-wrapper"
                        />
                    </If>

                    <If cond={!hideAllCarousels && this.showCarousel && product.usedImageList && product.carouselType == "preview" && !this.isMobileView()}>
                        <PreviewCarousel 
                            showCarousel={this.showCarousel} 
                            productActions={productActions}
                            index={index} 
                            moduleIndex={moduleIndex}
                            guid={guid}
                            config={config} 
                            product={product} 
                            getCarouselImageBoxes={helpers.getCarouselImageBoxes}
                            thumbCssClass="xsellfrontend-viewmore-images-70"
                            wrapperCssClass="xsellfrontend-product-carousel-main-wrapper"
                        />
                    </If>                    

                    <div className={this.showCarousel ? "xsellfrontend-standard-portrait-bottom-text-large-container with-carousel" : "xsellfrontend-standard-portrait-bottom-text-large-container"}>
                        <Button
                                style={helpers.getEditButtonStyle(addTocartStatus, helpers.overlay)}
                                cssClass="xsellfrontend-standard-portrait-bottom-text-large"
                                buttonClass="small button tertiary"
                                text={text}
                                onClickEvent={() => helpers.togglePopUp()}
                                dataTam="xsell_edit_button"
                        />
                    </div>

                    <DiscountRibbonsSmall index={index} store={DiscountRibbonsStore} product={product} config={config} />

                    <If cond={!this.isMobileView()}>
                        <span className="xsellfrontend-standard-portrait-left-text-container-header" data-tam='xsellfrontend-text-header'>
                                <div>
                                    <span data-tam='xsellfrontend-product-name'> {product.productTranslation.name} </span> 
                                    <span className="xsellfrontend-standard-portrait-left-text-container-header-description" data-tam='xsellfrontend-product-description'>{product.productTranslation.description}</span>        
                                </div>
                        </span>
                    </If>

                    <div className="xsellfrontend-standard-portrait-price-and-add-to-cart-container">
                        <div className={"xsellfrontend-standard-portrait-left-subheader"}  data-tam='xsellfrontend-subheader'>
                            <span>{helpers.getPriceTextSmall(product.articleType, config.language)}</span>
                            <span className={"xsellfrontend-standard-portrait-left-price xsellfrontend-primary-color"}>
                                <span>{helpers.getPriceLeft(product)}</span>
                            </span>
                        </div> 
                        <AddToCart 
                            skipComplete={showCheckOnOrderbutton}
                            addToCartRequest={product.addToCartRequest}
                            addToCartAction={() => productActions.addToCart(guid, index+1)}
                            initialButton={(onClick) => <ArcButton data-tam="add_to_cart_button" {...addToCartButtonProps} onClick={onClick}>{layoutHelpers.getOrderButtonText(config.language, product.articleType, config.identifiers, product.flow)}</ArcButton>}
                            loadingButton={(onClick) => <ArcButton data-tam="add_to_cart_button" {...addToCartButtonProps} disabled onClick={onClick}><img src={loaderGreen} className="xsellfrontend-loader-orderbutton" /></ArcButton>}
                            completeButton={(onClick) => <ArcButton data-tam="add_to_cart_button" {...addToCartButtonProps} onClick={onClick}>{locale[config.language]["addToBasketButtonTextClicked"]}</ArcButton>}
                            checkMarkButton={(onClick) => <ArcButton data-tam="add_to_cart_button" {...addToCartButtonProps} disabled onClick={onClick}><img width="25" height="25" className="xsellfrontend-add-to-cart-checkmark" src={check} /></ArcButton>}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default Portrait