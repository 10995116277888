import React, { Component } from 'react'
import helpers from '../../app/helpers'
import loaderOrange from '../../../graphic/xsellLoaderOrange.svg'
import locale from '../../app/locale'


class ProductFormatButtons extends Component {
    
    constructor(props){
        super(props)        
    }   

    render(){
        const { voucherStore, moduleIndex, index, loader, config, product, guid, updateValue, selectedValue } = this.props
        const voucher = product
        const list = voucher.groupLabelList || []

        return (
            <ul data-tam="xsellfrontend-voucher-buttongroup-dropdown" className={this.props.cssClass}>
              <select onChange={updateValue.bind(this)} value={selectedValue}>
               <option value="voucherText" disabled>{locale[config.language]["selectGiftVoucher"]}</option>
                {list.map((label, index) => <option key={helpers.generateGuid()} value={label.productInstanceId} data-tam="xsell_product_group_buttons" className={ "xsellfrontend-voucher-buttongroup-links "} > {label.name} </option>)}
              </select>
            </ul>
        )
    }

}

export default ProductFormatButtons