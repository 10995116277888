import locale from '../app/locale'
import React, { Fragment } from 'react'
import { Icon } from "@albelli/arc-3-react/lib/Icon";

const layoutHelpers = {
    
    getCarouselImageBoxes(number, html, maxImagesInCarousel){
        const drawHtml = (num = 0) => {
            return num === number || num === maxImagesInCarousel(window.innerWidth)
                ? []
                : [...html(num), ...drawHtml(num + 1)]
        }
        return drawHtml()
    },


    getPrice(product, classN = "xsellfrontend-moreinfo-discount-price-linethrough", extra = ""){
        return product.price.discountCode 
            ? <span> <strong className={product.price.discountPercent ? "xsellfrontend-primary-color" : "xsellfrontend-nodiscount-color"} >{product.price.formattedTotalPrice}{product.price.showAsterisk &&<span>*</span>}</strong> <span className={classN}>{product.price.formattedSubTotal}</span>{extra}  </span>
            : <strong className={product.price.discountPercent ? "xsellfrontend-primary-color" : "xsellfrontend-nodiscount-color"} >{product.price.formattedTotalPrice}{product.price.showAsterisk &&<span>*</span>}</strong>
    },


    getPriceLeft(product, classN = "xsellfrontend-moreinfo-discount-price-linethrough", extra = ""){
        let price = <span> <span>{product.price.formattedTotalPrice}{product.price.showAsterisk &&<span>*</span>}</span> <span className="xsellfrontend-standard-portrait-price-linethrough">{product.price.formattedSubTotal}</span>{extra}</span>
        if(product.articleType.includes("PhotoBook")){
            const style = { position:"relative", top: "7px" }
            price = <div style={style}> <span>{product.price.formattedTotalPrice}{product.price.showAsterisk &&<span>*</span>}</span> <div className="xsellfrontend-standard-portrait-price-linethrough" style={{marginTop:"3px"}}>{product.price.formattedSubTotal}</div>{extra}</div>
        }
        
        return product.price.discountCode 
            ? price
            : <strong>{product.price.formattedTotalPrice}{product.price.showAsterisk &&<span>*</span>} </strong>
    },
    
    isMobileView() {
        return window.innerWidth <= 600
    },
    

    getOrderButtonText(lang, articleType, identifiers, flow){ 
        return <Icon name="shopping_cart"/>
    },
    
    getCheckedButton(language, png){
        return <span>
            <img width="25" height="25" className="xsellfrontend-add-to-cart-checkmark-new" src={png} />
            <span className='xsellfrontend-add-to-cart-text-checked'>{locale[language]["addedToBasketOverlay"]}</span>
        </span>
    }
}

export default layoutHelpers