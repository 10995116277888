import React, { Component } from 'react'
import locale from '../../app/locale'
import layoutHelpers from '../../app/layoutHelpers'
import Formatbuttons from './Formatbuttons'

import {If} from '../../app/helpers'
import AddToCart from '../app/addToCart/AddToCart'
import { Button as ArcButton } from "@albelli/arc-3-react/lib/Button";
import loaderGreen from '../../../graphic/xsellLoaderGreen.svg'
import check from '../../../graphic/xsellCheck.png'



class ContentBoxTop extends Component{
   
    constructor(props){
        super(props)

        this.classContentBox = props.showCarousel && window.innerWidth < 800 ? "xsellfrontend-moreinfo-contentbox-top-seemore" : "xsellfrontend-moreinfo-contentbox-top" 
        this.updateContentBox = this.updateContentBox.bind(this)
    }

    render(){
        const { config, product, request, index, moduleIndex, update, disable, productActions, guid, translations, showCheckOnOrderbutton } = this.props
        const productSizeText = locale[config.language]["moreInfoProductSizesText"]    
        const tooglePopupBox = this.togglePopupBox(product.formatLabelList)
        const extraClass = !product.formatLabelList ? "xsellfrontend-moreinfo-contentbox-top-no-formatlist" : ""
        const addToCartButtonProps = {
            className: "xsellfrontend-moreinfo-add-to-cart-button"                  
        }

        return (
            <div className={this.classContentBox + " " + extraClass}>
                <h3 style={{marginTop : config.template == "homepage-carousel-portrait-arc-3" ? "60px" : "20px" }} className="xsellfrontend-moreinfo-headline" >{ translations.name } </h3>
                <div className="xsellfrontend-format-headline" style={tooglePopupBox}>{productSizeText}</div>
                <If cond={product.formatLabelList}>
                    <div className="arc3-select-dropdown" style={{"height" : "auto", "width": "100%"}}>
                        <Formatbuttons moduleIndex={moduleIndex} index={index} productActions={productActions} guid={guid} disable={disable} config={config} product={product} update={update} />
                    </div>
                </If>
                <div className="xsellfrontend-moreinfo-price-container">
                    <div className="xsellfrontend-moreinfo-discount-price">
                        <If cond={product.price.totalPrice > 0}>
                            <span id="xsellfrontendMoreinfoDiscountPriceSeemore">{layoutHelpers.getPrice(product)}</span> 
                            <div>
                                {product.price.showAsterisk && <a className="xsell-price-description popup" href={product.price.shippingInfoUri} target="_blank">*{product.price.description}</a>}
                                {!product.price.showAsterisk && <span className="xsell-price-description popup no-underline">{product.price.description}</span>}
                            </div>
                        </If>
                    </div>
                  
                    <AddToCart 
                            skipComplete={showCheckOnOrderbutton}
                            addToCartRequest={product.addToCartRequest}
                            addToCartAction={() => productActions.addToCart(guid, index + 1)}
                            initialButton={(onClick) => <ArcButton {...addToCartButtonProps} onClick={onClick}>{layoutHelpers.getOrderButtonText(config.language, product.articleType, config.identifiers, product.flow)}</ArcButton>}
                            loadingButton={(onClick) => <ArcButton {...addToCartButtonProps} disabled onClick={onClick}><img src={loaderGreen} className="xsellfrontend-loader-orderbutton" /></ArcButton>}
                            completeButton={(onClick) => <ArcButton {...addToCartButtonProps} onClick={onClick}>{locale[config.language]["addToBasketButtonTextClicked"]}</ArcButton>}
                            checkMarkButton={(onClick) => <ArcButton {...addToCartButtonProps} disabled onClick={onClick}><img width="25" height="25" className="xsellfrontend-add-to-cart-checkmark" src={check} /></ArcButton>}
                        />
                </div>
            </div>
        )    }


    componentDidMount(){
        window.addEventListener("resize", this.updateContentBox);
    }


    componentWillUnmount(){
        window.removeEventListener("resize", this.updateContentBox);
    }


    togglePopupBox(formatLabelList){
        return formatLabelList && formatLabelList.length > 0
            ? {display:'block'}
            : {display:'none'}
    }


    updateContentBox(){
        this.classContentBox = this.props.showCarousel && window.innerWidth < 800 ? "xsellfrontend-moreinfo-contentbox-top-seemore" : "xsellfrontend-moreinfo-contentbox-top" 
    }
}

export default ContentBoxTop