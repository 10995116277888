import React, {useEffect} from 'react'
import { If } from '../../app/helpers'
import locale from '../../app/locale'
import layout from '../../app/layout'
import PortraitARC3 from '../templates/Portrait/PortraitARC3'
import Landscape from '../templates/Landscape/Landscape'
import Voucher from '../templates/Voucher/Voucher'
import Portrait from '../templates/Portrait/Portrait'
import PortraitSimple from '../templates/Portrait/PortraitSimple'
import PortraitPhotobookLarge from '../templates/Portrait/PortraitPhotobookLarge'
import PortraitCarousel from '../slick/PortraitCarousel'
import PortraitPrints from '../templates/Portrait/PortraitPrints'
import DefaultWrapper from '../templates/DefaultWrapper'
import DiscountedProductPortrait from '../templates/Portrait/DiscountedProductPortrait'
const Products = props => {

    const xsellproducts = props.productStore.data.products.data
    const discountedCopyProducts = props.productStore.data.discountedCopyProducts.data
    let products = discountedCopyProducts ? [discountedCopyProducts].concat(xsellproducts) : xsellproducts

    /*
    Different kind of layouts are set here from the Layout class. The timeout is needed since React needs to rendered first before
    layout is applied
    */
   const getLayout = () => {
        const { config, productStore } = props

        let layoutType = "responsive"
                        
        if (config.oneRowProducts){
            layoutType = "oneRow"
        }

        if(config.maxHeight > 0){
            layoutType = "fixed"
        } 
        
        const createLayout = layout(config, productStore.data.products.length, productStore.data.products.data[0].guid, props.containerWidth, props.containerHeight, discountedCopyProducts).create(layoutType)        
        
        return createLayout()       
    }

    const setWrapperClass = () => {
        const { config } = props
        var className = "xsellfrontend-container-responsive"

        if(config.template.includes("landscape")){
            className = "xsellfrontend-container-default xsellfrontend-container-default-landscape"
        }

        return className
    }

    //Is the product a second copy photobook or perhaps a mini-copy fotobook or similar (not a regular xsell product)
    const isDiscountProduct = (product) => {
        return product.data.hasOwnProperty("isDiscountedSecondCopyProduct")
    }

    const getAbTest = (type, product) => {
        let abTest = null
        //Override template name in case of A/B test
        let abTestList = !isDiscountProduct(product) ? props.productStore.data.abTestData.data : props.productStore.data.discountAbTestData.data;
 
        if (abTestList != null && abTestList.length > 0) {
            abTestList.forEach(x => {
                if(x.type == type) {

                    if(Array.isArray(x.variation.papList) && x.variation.papList.length && x.variation.papList.includes(product.data.pap)) {
                        abTest = x
                    } 
                    
                    if (Array.isArray(x.variation.articleTypeList) && x.variation.articleTypeList.length && x.variation.articleTypeList.includes(product.data.articleType)) {
                        abTest = x
                    } 

                    //If neither paplist or articletypelist is set
                    if((!Array.isArray(x.variation.papList) || !x.variation.papList.length) && (!Array.isArray(x.variation.articleTypeList) || !x.variation.articleTypeList.length)) {
                        abTest = x
                    }
                }
            });
        }

        return abTest
    }

    const getTemplateName = (product) => {
        let abTest = getAbTest("frontendTemplate", product)
        return abTest !== null ? abTest.variation.frontendTemplate : props.config.template
    }

    const getTemplateWrapper = (templateName) => {
        if (templateName.includes("carousel")) {
            return PortraitCarousel
        } else {
            return DefaultWrapper
        }
    }

    //Which template to render the given product? 
    const getTemplate = (product) => {

        let templateName = getTemplateName(product)

        let template = null;

        switch (templateName) {
            case "checkout-portrait":
                template = Portrait
                
                //Override template in case of Voucher product
                if(product.data.articleType == "Voucher") {
                    template = Voucher
                }

                if(product.data.articleType == "Prints") {
                    template = PortraitPrints
                }

                //Override template in case of Discount Product (Second copy Photobook or similar)
                if(isDiscountProduct(product)) {
                    if(product.data.articleType.includes("PhotoBook")) {
                        template = PortraitPhotobookLarge
                    }
                    else {
                        template = DiscountedProductPortrait
                    }
                }
            break;
            case "homepage-carousel-portrait-arc-3":
                template = PortraitSimple
            break;                
            case "standard-portrait":
            case "mypage-portrait":
                template = Portrait
            break;
            case "mypage-portrait-arc-3":
                template = PortraitARC3
            break;
            case "standard-landscape":
                template = Landscape
            break;
            case "carousel-portrait":
                template = Portrait
            break;
            default: 
                throw Error("The template name " + templateName + " does not exist. Wrong template name in A/B test perhaps?")
            break;
        }

        return template
    }

    const getDataTam = (product) => {
        if (product.data.articleType === 'Voucher') {
            return 'voucher'
        }
        if (isDiscountProduct(product)) {
            return 'discount-product'
        }

        return 'image-product'
    }

    if (!products) {
        return <div></div>
    }

    // Filter out products if there is a A/B-test with a show / no-show
    products = products.filter((product) => {
        const abTest = getAbTest("frontendNoShow", product)
        if(!abTest) {
            return true
        }
        return !abTest.variation.noShow
    })
    
    if (products.length === 0) {
        return <div></div>
    }
    

    const layoutData = getLayout()
    let productsPerRow = layoutData.productsPerRow

    const displayData = {        
        moduleIndex: products[0].guid,
        numberOfProducts: products.length,
        imageContainerHeight: 238,
        productsPerRow: productsPerRow == 0 ? 1 : productsPerRow, 
        margin: products.map(() => {
            return layoutData.margin
        }),
        wrapperClass: setWrapperClass(),
        style: layoutData.style,
        productStyle: layoutData.productStyle,
        showAllProducts: props.productStore.data.showAllProducts,
        discountedCopyProducts:  props.productStore.data.discountedCopyProducts.data !== null
    }

    const TemplateWrapper = getTemplateWrapper(getTemplateName(products[0]))
    return  <div data-tam='xsell-container' id={"xsellcontainer" + displayData.moduleIndex} style={displayData.style} className={displayData.wrapperClass}> 
                <TemplateWrapper template={props.config.template.vendorId}>
                    {products.map((product, index) => {
                        
                        //Limit the number of products shown 
                        if ((!displayData.showAllProducts || props.config.oneRowProducts) && (index + 1 > displayData.productsPerRow) && window.innerWidth > 800) {
                            return;
                        }

                        const Template = getTemplate(product)
                        
                        return <Template
                                    dataTamProductType={getDataTam(product)}
                                    key={product.guid}
                                    imageContainerHeight={displayData.imageContainerHeight} 
                                    numberOfProducts={displayData.numberOfProducts}
                                    numberOfProductsPerRow={displayData.productsPerRow} 
                                    config={props.config} 
                                    product={product.data} 
                                    guid={product.guid} 
                                    productActions={isDiscountProduct(product) ? props.discountedCopyProductActions : props.productActions}
                                    hideAllCarousels={props.hideAllCarousels}
                                    index={index}
                                    moduleIndex={displayData.moduleIndex}
                                    products={products}
                                    showAllProducts={displayData.showAllProducts}
                                    productStyle={displayData.productStyle}
                                    showCheckOnOrderbutton={props.config.page === "checkout_landing" || props.config.page === "mypage"}
                                />
                    })}

                    {(props.config.page == "checkout_landing" || props.config.page == "mypage") && 
                        <div className="xsell-price-description-container">
                            {products[0].data.price.showAsterisk && <a className="xsell-price-description" href={products[0].data.price.shippingInfoUri} target="_blank">*{products[0].data.price.description}</a>}
                            {!products[0].data.price.showAsterisk && <span>{products[0].data.price.description}</span>}
                        </div>
                    }
                </TemplateWrapper>
    
                <If cond={ !displayData.showAllProducts && (displayData.numberOfProducts > displayData.productsPerRow) && window.innerWidth > 800} >
                    <div onClick={ () => { props.productActions.setShowAllProducts(true)} } className="xsell-show-more-products-container"> 
                        <span className="xsell-show-more-products-text"> {locale[props.config.language]["viewAllProducts"]}  <i className="icon arrow-right xsell-show-more-products-icon"></i> </span>
                    </div>
                </If> 
            </div>

}

export default Products