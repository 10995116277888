import React, { Component } from 'react'
import locale from '../../app/locale'
import helpers, { If } from '../../app/helpers'
import ImageCarousel from '../slick/Carousel'
import Thumb from '../../components/app/Thumb'

class Carousel extends Component{
  
    constructor(props){
        super(props)

        this.state = {
            thumbs: this.getLoadingThumbs(),
            selectedImage: props.product.usedImageList[0].imageId,
            shownImages: props.shownImages || 4
        }

        this.numberOfImagesInCarousel = 9
        this.cssClass = props.wrapperCssClass || "xsellfrontend-carousel-main-wrapper-small"
        
        this.loadAllThumbs()
    }

    getLoadingThumbs() {
        return this.props.getCarouselImageBoxes(
            this.numberOfImagesInCarousel,
            () => [<Thumb key={helpers.generateGuid()} />],
            this.props.config.maxImagesInCarousel
        )
    }

    getThumbs(productList) {
        if(productList !== null) {
            const {thumbCssClass} = this.props
            return productList.map((product, index) => {
                const hasBorder = product.usedImageList[0].imageId == this.state.selectedImage
                return <Thumb key={helpers.generateGuid()} border={hasBorder} updateThumb={this.updateThumb.bind(this, this.props, product)} url={this.getImageForProduct(this.props.product, product)} size='cover' cssClass={thumbCssClass} />
            })
        }        
    }

    getImageForProduct(mainProduct, carouselProduct) {
        try {
            let image = mainProduct.carouselImageList.find(img => img.imageId === carouselProduct.usedImageList[0].imageId)
            return image.thumbnailUrl   
        } catch(e) {
            console.error(e)
        }
    }

    loadAllThumbs(){
        const { productActions, guid, maxNumberOfProducts } = this.props
        productActions.updateCarouselProducts(guid, maxNumberOfProducts)               
    }

    updateThumb(props, product){
        const { productActions, guid } = props
        productActions.changeProduct(guid, product)      
        this.setState({selectedImage: product.usedImageList[0].imageId })
    }


    render(){
        const { config, index } = this.props
        const carouselProducts = this.props.product.carouselProducts
        const thumbs = carouselProducts.data ? this.getThumbs(carouselProducts.data) : this.getLoadingThumbs()
        
        return (
            <div className="xsellfrontend-carousel-main-wrapper">
                <If cond={this.props.showCarousel && thumbs !== undefined}>
                    <div className="xsellfrontend-carousel-try-different-photo-text">
                        <span className="xsellfrontend-carousel-try-different-photo-text-span">{ locale[config.language]["moreInfoTryButtonText"] }</span>
                    </div>
                    <span className="xsellfrontend-carousel-image-count">
                        {this.props.product.carouselImageList.map(function(o) { return o.imageId; }).indexOf(this.state.selectedImage) + 1} / {thumbs.length}
                    </span>
                    <ImageCarousel thumbs={ thumbs } />
                </If>
            </div>
        )

    }

}

export default Carousel
