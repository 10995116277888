import {doRequest, doPromiseRequest, defaultRemoteData} from './requestHelper'
import helpers from './../app/helpers'

class ProductRequests {
    constructor(config) {
        this.config = config   
    }

    getProducts(articleType = "", formatGroupKey = "", imageList, setDataCallback) {

        let url = this.config.xsellServiceUrl + "/api/v1/GetProducts"
        if (articleType && formatGroupKey) {
            url += "/" + articleType + "/" + formatGroupKey
        }

        const visitorData =  this.getAdobeTargetVisitorData()

        const callback = (remoteData) => {
            if (remoteData.status === "complete") {
                //Some preview images are only ment for cart, so we make a filtered previewImageList that 
                //consists of the previewImages we will actually show frontend
                remoteData.data.productItemList = remoteData.data.productItemList.map(p => {
                    p.filteredPreviewImageList = p.previewImageList.filter(x => !x.cartPreviewOnly)
                    return p
                })
            }
            setDataCallback(remoteData)
        }

        doRequest(url, {
            responseType: "json",
            method: "POST", 
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": this.config.accessToken(),
                "X-AdobeVisitorId": visitorData.marketingCloudVisitorId,
                "x-sessionid": visitorData.sessionId,
                "x-tntid": visitorData.tntId,
                "X-AdobeAccountId": visitorData.accountId
            },
            body: JSON.stringify(this._getProductPayload(imageList))
        }, callback)        
    }

    filterImagesForProduct(pap, imageList, setDataCallback) {
        let url = this.config.xsellServiceUrl + "/api/v1/FilterImagesForProduct/" + pap

        const visitorData =  this.getAdobeTargetVisitorData()
        
        doRequest(url, {
            responseType: "json",
            method: "POST", 
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": this.config.accessToken(),
                "X-AdobeVisitorId": visitorData.marketingCloudVisitorId,
                "x-sessionid": this._getSessionId(),
                "x-tntid": visitorData.tntId,
                "X-AdobeAccountId": visitorData.accountId
            },
            body: JSON.stringify(this._getProductPayload(imageList))
        }, setDataCallback)
    }

    getCarouselImageList(pap, numberOfPhotosNeeded, setDataCallback) {
        let url = this.config.xsellServiceUrl + "/api/v1/GetCarouselImageList/" + pap + "/" + numberOfPhotosNeeded
        
        const visitorData =  this.getAdobeTargetVisitorData()

        doRequest(url, {
            responseType: "json",
            method: "POST", 
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": this.config.accessToken(),
                "X-AdobeVisitorId": visitorData.marketingCloudVisitorId,
                "x-sessionid": this._getSessionId(),
                "x-tntid": visitorData.tntId,
                "X-AdobeAccountId": visitorData.accountId
            },
            body: JSON.stringify(this._getProductPayload())
        }, setDataCallback)
    }
    
    addToCart(product, setDataCallback) {
        let url = this.config.xsellServiceUrl + "/api/v1/AddToCart"

        const visitorData =  this.getAdobeTargetVisitorData()
        
        doRequest(url, {
            responseType: "json",
            method: "POST", 
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": this.config.accessToken(),
                "X-AdobeVisitorId": visitorData.marketingCloudVisitorId,
                "x-sessionid": this._getSessionId(),
                "x-tntid": visitorData.tntId,
                "X-AdobeAccountId": visitorData.accountId
            },
            body: JSON.stringify(this._getAddToCartPayload(product))
        }, setDataCallback)
    }

    addDiscountedSecondCopyToCart(product, setDataCallback) {
        let url = this.config.discountedProductUrl + "/api/v1/discountedproduct/addtocart"

        const visitorData =  this.getAdobeTargetVisitorData()
        
        doRequest(url, {
            responseType: "json",
            method: "POST", 
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": this.config.accessToken(),
                "X-AdobeVisitorId": visitorData.marketingCloudVisitorId,
                "x-sessionid": this._getSessionId(),
                "x-tntid": visitorData.tntId,
                "X-AdobeAccountId": visitorData.accountId
            },
            body: JSON.stringify(this._getAddToCartDiscounteProductPayload(product))
        }, setDataCallback)
    }

    getAdobeTargetVisitorData() {
        let sessionId = ""
        let tntId = ""

        let mboxCookie = helpers.getCookie('mbox')
        let pipeSplit = mboxCookie.split("|")
        pipeSplit.forEach((part) => {
            let hashtagSplit = part.split("#")
            if (hashtagSplit[0] == "session") {
                sessionId = hashtagSplit[1]
            }
            if (hashtagSplit[0] == "PC") {
                tntId = hashtagSplit[1]
            }
        })

        const analyticsCookie = this.getAdobeAnalyticsCookie()
        
        return {
            sessionId: sessionId,
            tntId: tntId,
            marketingCloudVisitorId: analyticsCookie ? analyticsCookie["MCMID"] : null,
            accountId: window?.AA_data?.["account-subject"] || ""
        }
    }

    getAdobeAnalyticsCookie() {
        const cookie = helpers.getCookie('AMCV_096A38B352782D5F0A490D4C%40AdobeOrg')
        if (cookie) {
            const cookieArray = cookie.split('|')
            cookieArray.shift()
            let keyValuePairs = {}
            for (var i = 0; i < cookieArray.length; i = i + 2) {
                keyValuePairs[cookieArray[i]] = cookieArray[i+1]
            }
            return keyValuePairs
        } else {
            return null
        }            
    }
    

    getDiscountedCopyProducts (setDataCallback) {
        if(this.config.inputProduct && this.config.inputProduct.CartId && this.config.inputProduct.Pap && this.config.inputProduct.CartItemId){

            const visitorData =  this.getAdobeTargetVisitorData()

            this.getCatalogProductDetails((remoteData) => {
                if (
                    remoteData.status === "complete" 
                    && remoteData.data 
                    && 
                    (remoteData.data.articleType.includes("PhotoBook") 
                    || remoteData.data.articleType.includes("Canvas")
                    || remoteData.data.articleType.includes("Poster")
                    || remoteData.data.articleType.includes("Aluminium")
                    || remoteData.data.articleType.includes("Forex")
                    || remoteData.data.articleType.includes("Perspex")
                    || remoteData.data.articleType.includes("Mug")))
                    {

                    let url = this.config.discountedProductUrl + "/api/v1/discountedproduct/getproduct"
                
                    doRequest(url, {
                        responseType: "json",
                        method: "POST", 
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "X-AdobeVisitorId": visitorData.marketingCloudVisitorId,
                            "x-sessionid": this._getSessionId(),
                            "x-tntid": visitorData.tntId,
                            "X-AdobeAccountId": visitorData.accountId
                        },
                        body: JSON.stringify(this._getDiscountedCopyProductPayload())
                    }, setDataCallback)    
                } else if (remoteData.status === "complete" || remoteData.status === "error") {
                    setDataCallback({...defaultRemoteData, status: "complete"})
                }

            })                   
        } else {
            setDataCallback({...defaultRemoteData, status: "complete"})
        }
    }

    getCatalogProductDetails(setDataCallback) {
        
        let url = this.config.catalogDetailsUrl + "/api/v1/details/" + this.config.inputProduct.Pap + "/" + this.config.vendorId
        
        doRequest(url, {
            responseType: "json",
            method: "GET", 
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: null
        }, setDataCallback)                  
    }

    notifyAnalyticsWithAdobeTargetTest(url) {
        doRequest(url, {
            responseType: "text",
            method: "GET", 
            headers: {
                "Content-Type": "application/json"            
            },
            body: null
        }, remoteData => {
            if (remoteData.status === "error") {
                console.error("Failed to notify adobe target with error: " + JSON.stringify(remoteData.error))
            }
        })   
    }
    
    log (json = {}){
        const basicJson = {
            component: "xsell-frontend",
            payload: this._getProductPayload(),
            sessionId: sessionStorage.getItem('xsell-session')
        }
        const payload = { "message":  JSON.stringify({...basicJson, ...json }), "LogLevel": json.loglevel }

        const visitorData =  this.getAdobeTargetVisitorData()
        
        doRequest(this.config.xsellServiceUrl + "/api/v1/logmessage", {
            responseType: "text",
            method: "POST", 
            headers: {
                "Content-Type": "application/json",
                "Accept": "text/html",
                "Authorization": this.config.accessToken(),
                "X-AdobeVisitorId": visitorData.marketingCloudVisitorId,
                "x-sessionid": sessionStorage.getItem("xsell-session"),
                "x-tntid": visitorData.tntId,
                "X-AdobeAccountId": visitorData.accountId
            },
            body: JSON.stringify(payload)
        }, remoteData => {})          
    }

    _getAddToCartPayload(product){
        return {
            'VendorId': this.config.vendorId,
            'Channel': this.config.channel,
            'Page': this.config.page,
            'ProductInstanceId': product.id,
            'Pap': product.pap,
            'AlbxLocation': product.albxLocation,
            'Amount':1,
            'PreviewImageList': product.previewImageList,
            'AdditionalData': product.additionalData,
            'DiscountCode': product.price ? product.price.discountCode : null,
            'ProductDescription': product.productDescription,
            'CartId': this.config.inputProduct ? this.config.inputProduct.CartId : null
        }
    }


    _getAddToCartDiscounteProductPayload(product){
        return {
            'Pap': product.pap,
            'Qty': 1,
            'CartId': this.config.inputProduct ? this.config.inputProduct.CartId : null,
            'CartItemId': this.config.inputProduct ? this.config.inputProduct.CartItemId : null,
            'CopyType': product.copyType
        }
    }


    _getProductPayload(inputList){
        return {
            'VendorId': this.config.vendorId,
            'Channel': this.config.channel,
            'Page': this.config.page,
            'InputImageList': inputList || this.config.inputImageList,
            'Language': this.config.language,
            'Device': this.config.device,
            'InputProduct': this.config.inputProduct,
            'NumberOfProducts': this.config.numberOfProducts
        }
    }


    _getDiscountedCopyProductPayload(){
        return {
            "CartId": this.config.inputProduct ? this.config.inputProduct.CartId : null,
            "CartItemId": this.config.inputProduct ? this.config.inputProduct.CartItemId : null         
          }
    }

    _getSessionId() {
        if (sessionStorage) {
            return sessionStorage.getItem("xsell-session")
        }
        return ""
    }

   
}

export default ProductRequests