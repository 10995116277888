import React from 'react'
import ReactMarkdown from 'react-markdown'
import Locale from '../../app/locale'

const MarketingText = ({config, marketingText, showOnlyVisibleToYouTag}) => {

    if(!marketingText){
        return <div style={{minHeight:0.1}}></div>
    }
     
    return (
        <>
            <div className='xsellfrontend-marketingtext'>
                <ReactMarkdown>{marketingText.content}</ReactMarkdown>
                {showOnlyVisibleToYouTag && <div className="xsellfrontend-onlyvisibletoyoutag">{Locale[config.language]["onlyVisibleToYou"]}</div>}
            </div>
            
        </>
    )
}

export default MarketingText