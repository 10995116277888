async function doRequest(url, params, setData) {
    setData({data: null, error: "", status: "loading", responseCode: null})

    let response = null
    try {
        response = await fetch(url, params)
    } catch (error) {
        setData({status: "error", data: null, error: "Request to " + url + " failed with error: " + error, responseCode: response ? response.status : null})
        return
    }   

    if (response.ok) {
        let responseData = ""
        try {
            switch (params.responseType) {
                case "arrayBuffer": 
                    responseData = await response.arrayBuffer()
                break; 
                case "text": 
                    responseData = await response.text()
                break; 
                case "blob": 
                    responseData = await response.blob()
                break; 
                case "json": 
                    responseData = await response.json()
                break;                    
            }
            setData({status: "complete", data: responseData, error: "", responseCode: response.status}) 
        } catch (error) {
            setData({status: "error", data: null, error: "Failed to parse response data into " + params.responseType + ". Error: " + error, responseCode: response.status})
        }          
    } else {
        setData({status: "error", data: null, error: "Request to " + url + " failed with status: " + response.status + ". Body: " + await response.text(), responseCode: response.status})
    }
}

async function doPromiseRequest(url, params) {
    return new Promise((resolve, reject) => {
        doRequest(url, params, (remoteData) => {
            if (remoteData.status === "complete") {
                resolve(remoteData.data)
            }
            if (remoteData.status === "error") {
                reject(remoteData.error)
            }
        })
    })
}

const defaultRemoteData = {status: "initial", data: null, error: "", responseCode: null}

export {doRequest, doPromiseRequest, defaultRemoteData}