import React, { Component } from 'react'
import locale from '../../app/locale'
import DoubleArrowIcon from "../../../graphic/double_arrow.svg"
import PrevArrow from '../slick/PrevArrow'
import NextArrow from '../slick/NextArrow'

class ImageContainer extends Component {

    constructor(props){
        super(props)
    }

    render(){
        const { product } = this.props

        return (
            <div className="xsellfrontend-standard-moreinfo-image-container" data-tam="xsell_more_info">
                {this.props.product.filteredPreviewImageList.length > 1 && <NextArrow onClick={() => this.nextCycleImage()} />}
                {this.props.product.filteredPreviewImageList.length > 1 && <PrevArrow onClick={() => this.prevCycleImage()} />}
                {/* { (product.articleType !== "Prints" && product.carouselType !== "preview") && 
                    <div className="xsellfrontend-popup-edit-photo-button" onClick={() => this.props.closeCallBack()}>
                        <div className="xsellfrontend-popup-edit-photo-button-text"> {locale[this.props.translations.lang]["editPhoto"]}</div>
                        <img className="xsellfrontend-popup-edit-photo-button-icon" src={DoubleArrowIcon}></img>
                    </div>
                } */}
                <img className="xsellfrontend-standard-moreinfo-image" src={product.filteredPreviewImageList[this.props.product.selectedPreview].url} />
                <div className="xsellfrontend-standard-moreinfo-image-container-cycle-selector">
                    { this.renderSelector(product.filteredPreviewImageList, product.selectedPreview) }
                </div>
            </div>
        )
    }

    renderSelector(previewImageList, key){
        if(!previewImageList || previewImageList.length <= 1) return
        
        return previewImageList.map((image, i) => {
            const style = key === i ? { backgroundColor: "#38bfc4" } : {}
            return <span key={i} style={style} ></span>
        })
    }

    nextCycleImage(){
        const { product, productActions, guid } = this.props
        var cycleImageCount = this.props.product.filteredPreviewImageList.length;
        if (cycleImageCount > 1) {
            var nextCycleKey = product.selectedPreview + 1
            if (nextCycleKey == cycleImageCount) {
              nextCycleKey = 0
            }

            productActions.updateProductSelectedPreview(guid, nextCycleKey)
  
        }
    }

    prevCycleImage() {
        const { product, productActions, guid } = this.props
        var cycleImageCount = this.props.product.filteredPreviewImageList.length;
        if (cycleImageCount > 1) {
            var prevCycleKey = product.selectedPreview - 1
            if (prevCycleKey == -1) {
                prevCycleKey = cycleImageCount - 1
            }

            productActions.updateProductSelectedPreview(guid, prevCycleKey)
  
        } 
    }
}

export default ImageContainer