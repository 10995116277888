class Config {
    backendCarouselAmount = "30"

    maxImagesInCarousel = width => {
        if(width >= 1300){
            return 12
        } else if (width < 800){
            return 8
        } else {
            return 9
        }
    }
    
    maxImagesInSmallCarousel = 9

    identifiers = {
        checkout: "checkout_productlandingpage",
        prints: "Prints",
        goods: "GoodsStatic",
        po: "Photo Organizer"
    }

    minWidth = {
        portrait: 230
    }
    xsellServiceUrl = "#{xsellServiceUrl}"
    poPhotoApiUrl = "#{poPhotoApiUrl}"
    discountedProductUrl = "#{discountedProductUrl}"
    catalogDetailsUrl = "#{catalogDetailsUrl}"
    adobeTargetUrl = "https://albumprinter.tt.omtrdc.net/rest/v2/batchmbox?client=albumprinter"

    redirectTimeout = "#{xsellRedirectTimeout}"
    vendorId = ""
    channel = ""
    page = ""
    inputImageSource = ""
    device = ""
    inputImageList = null
    inputProduct = ""
    template = ""
    carouselInputImageList = []
    aALocation = ""
    language = ""
    productListCallback = null
    aBTestingCallback = null
    userId = ""
    accessToken = null
    showCarousel = true
    showMobileCarousel = false
    oneRowProducts = false
    numberOfProducts = 4
    redirectUrl = ""
    maxHeight = 0
    loadHeadInCarousel = false
    addToCartCallback = null
    useDefaultLoadingScreen = false
    showOnlyVisibleToYouTag = false
    showLoader = true 
    minNumberOfProducts = 0
}

export default Config