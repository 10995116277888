import React, { Component } from 'react'
import MarketingText from './app/MarketingText'
import Products from './app/Products'
import LoadingScreen from './app/LoadingScreen'
import debounce from 'debounce'


class App extends Component {

    constructor(props) {
        super(props)
        this.state = {productStore: props.productStore.getData(), error: "", containerWidth: 0, containerHeight: 0}
        this.productStoreUpdates = this.productStoreUpdates.bind(this)    
        this.handleResize = debounce(this.handleResize.bind(this), 50) //Don't respond to every single resize event, just one every x milliseconds   
    }

    productStoreUpdates() {
        const data = this.props.productStore.getData()

        this.setState({productStore: data})

        //Handle empty product response
        if (data.products.status === 'complete' && data.discountedCopyProducts.status === 'complete' && (!data.products.data || !data.products.data.length)  && (!data.discountedCopyProducts.data || data.discountedCopyProducts.data.length)) {
            
            this.props.productActions.log({
                message: "Xsell Frontend - Empty product list response", 
                error: "",
                loglevel: "Information"
            })
            
            if (this.props.config.redirectUrl) {
                window.location = this.props.config.redirectUrl
            }

            if(this.props.config.productListCallback){
                this.props.config.productListCallback();
            }

            if (typeof(Storage) !== "undefined") {
                if (sessionStorage.getItem('xsell-session')) {
                    throw "Empty productItemList returned. Following session id can be used to search the logs: " + sessionStorage.getItem('xsell-session');
                }              
            }
        }
        
    }

    handleResize() {
        this.setState({containerWidth: this.props.containerElement.getBoundingClientRect().width, containerHeight: this.props.containerElement.getBoundingClientRect().height})        
    }

    componentDidCatch(error, errorInfo) {
        console.error("Error in childcomponent of app", error, errorInfo)

        this.props.productActions.log({
            message: "Xsell Frontend - " + error, 
            error: errorInfo,
            loglevel: "Critical"
        })
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show error message
        return { error: error };
    }

    componentDidMount() {
        this.props.productStore.subscribe(this.productStoreUpdates)    
        window.addEventListener('resize', this.handleResize);    
        setTimeout(() => {
            this.handleResize()
        }, 500)
    }

    componentWillUnmount() {
        this.props.productStore.unsubscribe(this.productStoreUpdates)
        window.removeEventListener('resize', this.handleResize);
    }

    render () {
        const productStatus = this.state.productStore.products.status
        const discountedCopyProductStatus = this.state.productStore.discountedCopyProducts.status
        const xsellProductCount = this.state.productStore.products.data ? this.state.productStore.products.data.length : 0
        const discountProductCount = this.state.productStore.discountedCopyProducts.data ? 1 : 0
        const numberOfProducts = xsellProductCount + discountProductCount
        const config = this.props.config
        
        if (this.state.error || this.state.productStore.error) {
            return <div></div>
        } else if (config.showLoader && (productStatus === "loading" || productStatus == "initial" || discountedCopyProductStatus === "loading" || discountedCopyProductStatus === "initial")) {
            return <LoadingScreen config={this.props.config} />
        } else if (numberOfProducts && (config.minNumberOfProducts == 0 || (config.minNumberOfProducts > 0 && numberOfProducts >= config.minNumberOfProducts) )) {
            return  <>
                        <MarketingText config={this.props.config} showOnlyVisibleToYouTag={this.props.config.showOnlyVisibleToYouTag} marketingText={this.state.productStore.marketingText.data} />
                        <Products productStore={this.props.productStore} containerWidth={this.state.containerWidth} containerHeight={this.state.containerHeight} hideAllCarousels={this.props.productStore.hideAllCarousels()} config={this.props.config} productActions={this.props.productActions} discountedCopyProductActions={this.props.discountedCopyProductActions} />
                    </>
        } else {
            return <div></div>
        }
        
    }

}

export default App