/* TO BE DEPRECATED */

const popup = {

    close(guid, target = " .xsellfrontend-moreinfo-wrapper"){
        document.querySelector("#xsellPopupWrapper" + guid + target).style.display = "none";
    },


    appendPopupToBody(html = "", guid, doc = document){
        if(doc.getElementById("xsellPopupWrapper" + guid)) return 
        var wrapper = doc.createElement("div")
        wrapper.innerHTML = html
        wrapper.id = "xsellPopupWrapper" + guid
        doc.body.appendChild(wrapper)
    },

}

export default popup