import helpers from '../app/helpers'
import {defaultRemoteData} from '../requests/requestHelper'

class DiscountedCopyProductActions {

    constructor (productStore, requests, config, analytics) {
        this.productStore = productStore
        this.requests = requests
        this.config = config
        this.analytics = analytics
    }

    loadDiscountedCopyProduct(template) {
        const createProduct = products => {
            return {
                data: this._setProductDefaultValues(products),
                guid: helpers.generateGuid()
            }
        }
        
        if (template === "checkout-portrait") {
            this.requests.getDiscountedCopyProducts((remoteData) => {                       
                if(remoteData.responseCode == 404) {
                    remoteData.status = "complete"
                }
    
                if (remoteData.status === "error") {
                    this.productStore.setError(remoteData.error)
                }

                if(remoteData.data) {
                    this.productStore.setDiscountAbTestData({...remoteData, data: remoteData.data.product.length > 0 ? remoteData.data.abTestData : null})
                    this.productStore.setDiscountedCopyProducts({...remoteData, data: remoteData.data.product.length > 0 ? createProduct(remoteData.data.product) : null})
                } else {
                    this.productStore.setDiscountAbTestData({...remoteData, data: null})
                    this.productStore.setDiscountedCopyProducts({...remoteData, data: null})
                }
    
            })     
        } else {
            this.productStore.setDiscountAbTestData({...defaultRemoteData, status: "complete"})
            this.productStore.setDiscountedCopyProducts({...defaultRemoteData, status: "complete"})
        }
    }

    addToCart(guid, pap, position) {
        let product = this.productStore.getDiscountedSecondCopyProduct()?.data
        
        if (product) {
            product = product.pap == pap ? product : product.copies.find(c => c.pap == pap)

            this.requests.addDiscountedSecondCopyToCart(product, (remoteData) => {
                this.productStore.setDiscountedSecondCopyProductAddToCartData(remoteData, product)
    
                if (remoteData.status === "error") {
                    this.productStore.setError(remoteData.error)
                }

                if (remoteData.status === "complete") {
                    if (this.config.addToCartCallback) {
                        this.config.addToCartCallback()
                    }

                    this.analytics.populateClick(product, this.config, position)
                }
            })
        }        
    }

    _setProductDefaultValues (products) {
  
        products.forEach((product) => {
            if (!product.hasOwnProperty("addToCartRequest")) {
                product.addToCartRequest = defaultRemoteData
            }
            if (!product.hasOwnProperty("isDiscountedSecondCopyProduct")) {
                product.isDiscountedSecondCopyProduct = true
            }
        })
        
        const p = {...products[0]}
        p.copies = products.filter(x => x.copyType !== "SecondCopy" && p.pap !== x.pap)
        return p
    }    
  
    catchError(ex){
        console.error(ex)

        this.productStore.setError(ex)        
        
        if (typeof(Storage) !== "undefined") {
            if (sessionStorage.getItem('xsell-session')) {
                throw "Failed to get product carousel from xsell service. Following session id can be used to search the logs: " + sessionStorage.getItem('xsell-session');
            }
        }
    }
}

export default DiscountedCopyProductActions