import React, { Component } from 'react'
import ProductThumb from './Thumb'
import helpers, { If } from '../../app/helpers'
import SlickProductCarousel from '../slick/ProductCarousel'
import carousel from '../../app/carousel'


class PreviewCarousel extends Component{

    constructor(props){
        super(props)
        this.numberOfImagesInCarousel = 13
        this.initialShownImages = props.shownImages || 4
        this.cssClass = props.wrapperCssClass || "xsellfrontend-carousel-main-wrapper-small"
    }


    render(){
        const { config } = this.props
        return (
            <div className={this.cssClass}>
                <If cond={this.props.showCarousel}>
                    <div data-tam="xsell-carousel-container" className="xsellfrontend-carousel-wrapper-small slick-wrapper">
                        <SlickProductCarousel config={config} thumbs={ this.getThumbs(this.props) } shownImages={this.initialShownImages} />
                    </div>
                </If>
            </div>
        )
    }

    getThumbs = (props) => {
        var images = props.product.filteredPreviewImageList
        var previews = this.createThumbs(props, images)
        return previews
    }


    createThumbs(props, images){
        const { config, thumbCssClass, product } = props
        return images
            .filter((image, i) => i <= this.numberOfImagesInCarousel)
            .map((image, i) => {
                return <ProductThumb key={helpers.generateGuid()} border={product.selectedPreview === i} updateThumb={ () => { this.updateThumb(props, product, i) } } url={image.url} size='cover' cssClass={thumbCssClass} />
            })
    }

    updateThumb(props, product, i){
        const { productActions, guid } = props
        productActions.updateProductSelectedPreview(guid, i)
    }

}

export default PreviewCarousel
