import React from 'react'
import {If} from '../../app/helpers'


const ImageContainerSmall = props => {
        var { overlay, updateImageContainer, updateOverlay, cssClass, status, product, togglePopUp } = props
    
    if(!updateImageContainer){
        updateImageContainer = () => {}
    }

    var url
    var transformation

    if(product.filteredPreviewImageList && product.filteredPreviewImageList[product.selectedPreview]){
        url = product.filteredPreviewImageList[product.selectedPreview].url
        transformation = product.filteredPreviewImageList[product.selectedPreview].transformation
    } 

    if (product.previews) {
        url = product.previews[0].url
    }

    const setOverlay = show => {
        if(status === "complete") return
        updateOverlay(show)
    }
    
    return (
        <div onClick={() => togglePopUp()} onMouseOut={() => setOverlay(false)} onMouseOver={() => setOverlay(true)} className={cssClass} data-tam="xsell_product_thumbnails">
            <If cond={window.innerWidth < 800}>
                <If cond={status === "complete"}>
                    <img onLoad={(e) => updateImageContainer(e.target)} src={url}  />
                </If>
                <If cond={status !== "complete"}>
                    <img onLoad={(e) => updateImageContainer(e.target)} src={url}  />
                </If>
            </If>

            <If cond={window.innerWidth >= 800}>
                <img className="xsell-product-thumbnail-image" onLoad={(e) => updateImageContainer(e.target)} src={url}  />
            </If>
        </div>
    )
}

export default ImageContainerSmall
