import React, { Component } from 'react'
import locale from '../../../app/locale'
import ImageContainerSmall from '../../app/ImageContainerSmall'
import DiscountRibbons from '../../app/DiscountRibbons'
import DiscountRibbonsSmall from '../../app/DiscountRibbonsSmall'
import DiscountRibbonsStore from '../../../app/DiscountRibbonsStyle'
import templateHelpers from '../templateHelpers'
import AddToCart from '../../app/addToCart/AddToCart'
import { Button as ArcButton } from "@albelli/arc-3-react/lib/Button";
import loaderGreen from '../../../../graphic/xsellLoaderGreen.svg'
import check from '../../../../graphic/xsellCheck.png'
import layoutHelpers from '../../../app/layoutHelpers'
import {If} from '../../../app/helpers'


@templateHelpers 
class DiscountedProductPortrait extends Component{
    
    constructor(props){
        super(props)
        this.state = {
            selectedPap: props.product.pap
        }
        this.showProductCarousel = this.props.config.showCarousel
        this.hasImagesForCarousel = true
        this.qty = 1
        this.discountedProduct = props.product
        this.photoBookOrientation
    }

    isMobileView() {
        return window.innerWidth <= 600
    }

    
    render(){
        const { config, product, productStyle, helpers, moduleIndex, index, imageContainerHeight, products, productActions, guid, dataTamProductType, showCheckOnOrderbutton } = this.props
        let addTocartStatus = product.addToCartRequest.status
        if(config.oneRowProducts && window.innerWidth > 800){
            return null
        }

        const addToCartButtonProps = {
            secondary: true,
            className: "xsellfrontend-standard-portrait-add-to-basket-button-large"                  
        }
        let selectedProduct = this.discountedProduct.pap == this.state.selectedPap ? this.discountedProduct : this.discountedProduct.copies.find(c => c.pap == this.state.selectedPap)
        return (
            <div data-tam='xsellfrontend-wrapper' data-tam-product-type={dataTamProductType} style={productStyle} className={"xsellfrontend-standard-portrait-wrapper xsellfrontend-standard-portrait-wrapper-discountedProduct"}>
                
                <If cond={this.isMobileView()}>

                    <If cond={product.price.discountPercent > 0}>
                        <DiscountRibbons 
                            index={index} store={DiscountRibbonsStore} 
                            text={"discountedProductDiscountText-"+this.discountedProduct.articleType}
                            withDiscount="true" 
                            product={selectedProduct} 
                            config={config} 
                            cssClass="xsellfrontend-discount-ribbon-large mobile" 
                        />
                    </If>

                    <div className="xsellfrontend-standard-portrait-gift-idea-title">
                        <span style={{fontWeight: "bold"}}>{locale[config.language]["secondCopyPhotobookGiftIdea"]}</span>
                    </div>
                    <div className="xsellfrontend-standard-portrait-gift-idea-text">
                        <span style={{fontWeight: "normal"}}>{locale[config.language]["discountedProductGiftIdeaDescription-"+this.discountedProduct.articleType]}</span>
                    </div>

                    <ImageContainerSmall 
                        height={imageContainerHeight} 
                        updateOverlay={helpers.updateOverlay} 
                        overlay={helpers.overlay} 
                        product={selectedProduct} 
                        openPopup={helpers.togglePopUp} 
                        status={addTocartStatus} 
                        language={config.language} 
                        updateImageContainer={this.updateImageContainer.bind(this)}
                        photoBookOrientation={this.photoBookOrientation}
                        cssClass={"xsellfrontend-standard-portrait-image-container"} 
                    />

                    {/* This us used as a place holder so that the text container under can have posistion: absolute and bottom: 0px. With this we can asure that the buttons are aligned with the other xsell buttons */}
                    <div className="xsellfrontend-standard-portrait-text-placeholder" ></div>

                    <div className={"xsellfrontend-standard-portrait-text-container"} data-tam="xsell_products">
                        {product.projectName}

                        <div className="xsellfrontend-standard-portrait-price-and-add-to-cart-container">
                            <div className={"xsellfrontend-standard-portrait-left-subheader"}  data-tam='xsellfrontend-subheader'>
                                <span>{helpers.getPriceTextSmall(product.articleType, config.language)}</span>
                                <span className={"xsellfrontend-standard-portrait-left-price xsellfrontend-primary-color"}>
                                    <span>{helpers.getPriceLeft(product)}</span>
                                </span>
                            </div> 
                            <AddToCart 
                                skipComplete={showCheckOnOrderbutton}
                                addToCartRequest={product.addToCartRequest}
                                addToCartAction={() => productActions.addToCart(guid, this.state.selectedPap, index+1)}
                                initialButton={(onClick) => <ArcButton data-tam="add_to_cart_button" {...addToCartButtonProps} onClick={onClick}>{layoutHelpers.getOrderButtonText(config.language, product.articleType, config.identifiers, product.flow)}</ArcButton>}
                                loadingButton={(onClick) => <ArcButton data-tam="add_to_cart_button" {...addToCartButtonProps} disabled onClick={onClick}><img src={loaderGreen} className="xsellfrontend-loader-orderbutton" /></ArcButton>}
                                completeButton={(onClick) => <ArcButton data-tam="add_to_cart_button" {...addToCartButtonProps} onClick={onClick}>{locale[config.language]["addToBasketButtonTextClicked"]}</ArcButton>}
                                checkMarkButton={(onClick) => <ArcButton data-tam="add_to_cart_button" {...addToCartButtonProps} disabled onClick={onClick}><img width="25" height="25" className="xsellfrontend-add-to-cart-checkmark" src={check} /></ArcButton>}
                            />
                        </div>
                    </div>
                     

                </If>

                <If cond={!this.isMobileView()}>

                    <If cond={product.price.discountPercent > 0}>
                        <DiscountRibbons index={index} store={DiscountRibbonsStore} text={"discountedProductDiscountText-"+this.discountedProduct.articleType} withDiscount="true" product={selectedProduct} config={config} cssClass="xsellfrontend-discount-ribbon-large" />
                    </If>
                    <ImageContainerSmall 
                        height={imageContainerHeight} 
                        updateOverlay={helpers.updateOverlay} 
                        overlay={helpers.overlay} 
                        product={selectedProduct} 
                        openPopup={helpers.togglePopUp} 
                        status={addTocartStatus} 
                        language={config.language} 
                        updateImageContainer={this.updateImageContainer.bind(this)}
                        photoBookOrientation={this.photoBookOrientation}
                        cssClass={"xsellfrontend-standard-portrait-image-container"} 
                    />

                    <div className={"xsellfrontend-standard-portrait-text-container"} data-tam="xsell_products">
                        
                        <If cond={product.price.discountPercent > 0}>
                            <DiscountRibbonsSmall index={index} store={DiscountRibbonsStore} product={this.discountedProduct} config={config} />
                        </If>
                        
                        <span className="xsellfrontend-standard-portrait-left-text-container-header" data-tam='xsellfrontend-text-header'>
                            <div style={{marginBottom: "30px"}}>
                                <span style={{fontWeight: "bold"}}>{locale[config.language]["secondCopyPhotobookGiftIdea"]}</span>&nbsp;
                                <span style={{fontWeight: "normal"}}>{locale[config.language]["discountedProductGiftIdeaDescription-"+this.discountedProduct.articleType]}</span>
                            </div>
                            <div><span>{product.projectName}</span></div>
                        </span>    

                        <div className="xsellfrontend-standard-portrait-price-and-add-to-cart-container">
                            <div className={"xsellfrontend-standard-portrait-left-subheader"} data-tam='xsellfrontend-subheader'>
                                <span>{helpers.getPriceTextSmall(this.discountedProduct.articleType, config.language)}</span>
                                <span className={"xsellfrontend-standard-portrait-left-price xsellfrontend-primary-color"}>
                                {helpers.getPriceLeft(selectedProduct)}
                                </span>
                            </div>
                      
                            {helpers.getDiscountBox("portrait")}

                            <AddToCart 
                                skipComplete={showCheckOnOrderbutton}
                                addToCartRequest={selectedProduct.addToCartRequest}
                                addToCartAction={() => productActions.addToCart(guid, this.state.selectedPap, index + 1)}
                                initialButton={(onClick) => <ArcButton data-tam="add_to_cart_button" {...addToCartButtonProps} onClick={onClick}>{layoutHelpers.getOrderButtonText(config.language, product.articleType, config.identifiers, "Cart")}</ArcButton>}
                                loadingButton={(onClick) => <ArcButton data-tam="add_to_cart_button" {...addToCartButtonProps} disabled onClick={onClick}><img src={loaderGreen} className="xsellfrontend-loader-orderbutton" /></ArcButton>}
                                completeButton={(onClick) => <ArcButton data-tam="add_to_cart_button" {...addToCartButtonProps} onClick={onClick}>{locale[config.language]["addToBasketButtonTextClicked"]}</ArcButton>}
                                checkMarkButton={(onClick) => <ArcButton data-tam="add_to_cart_button" {...addToCartButtonProps} disabled onClick={onClick}><img width="25" height="25" className="xsellfrontend-add-to-cart-checkmark" src={check} /></ArcButton>}
                            />
                        </div>
                    </div>
                </If>
            </div>
        )
    }

    updateImageContainer(img){
        if(!this.props.product.projectName) return
        
        const width = img.naturalWidth
        const height = img.naturalHeight

        if(width > height){ 
            this.photoBookOrientation = "landscape"
        }

        if(width < height){ 
            this.photoBookOrientation = "portrait"
        }

        if(this.isSquare(width, height)) { 
            this.photoBookOrientation = "square"
        }
    }

    isSquare(width, height){
        const allowedDiffForSquare = 50
        return width === height 
            || ((width - height) < allowedDiffForSquare && (width - height) > 0) 
            || ((width - height) > -allowedDiffForSquare && (width - height) < 0) 
    }
}

export default DiscountedProductPortrait