import React, { Component } from 'react'

const TextContainerHeader = ({product, type}) => {
    return (
        <span data-tam='xsellfrontend-text-header' className={"xsellfrontend-" + type + "-text-container-header"}>
            <div data-tam='xsellfrontend-product-name'>{product.productTranslation.name}</div>
            <div className={"xsellfrontend-" + type + "-text-container-header-description"} data-tam='xsellfrontend-product-description'>{product.productTranslation.description}</div>
        </span>
    )
}

export default TextContainerHeader