import React, { Component } from 'react'
import layoutHelpers from '../../app/layoutHelpers'
import Wrapper from './Wrapper'
import locale from '../../app/locale'
import { Portal } from 'react-portal'
import AddToCart from '../app/addToCart/AddToCart'
import { Button as ArcButton } from "@albelli/arc-3-react/lib/Button";
import loaderGreen from '../../../graphic/xsellLoaderGreen.svg'
import check from '../../../graphic/xsellCheck.png'
import { ContainerListView, Item, RowLayout, FlexLayout } from '@albelli/list-view-react'

class PopupPrints extends Component{
 
    constructor(props){
        super(props)
        this.state = {
            showEditor: false
        }
    }

    toggleEditor () {
        this.setState({showEditor: !this.state.showEditor})
    }

    render(){
        const { config, moduleIndex, guid, index, showCarousel, product, togglePopUp, getCarouselImageBoxes, productActions, maxNumberOfProducts, showCheckOnOrderbutton } = this.props
        const translations = product.productTranslation

        const items = product.usedImageList.map((image) => {
            return {
                id: image.imageId,
                url: image.thumbnailUrl,
                size: { width: image.visibleWidth, height: image.visibleHeight }
            }
        })

        const layout = new FlexLayout(150, 5, 5)


        const setItemElement = (id, urlThumbnail) => {
            const background = { backgroundImage: `url(${urlThumbnail})` }
            return <img data-tam="prints_image_thumbnails" className="xsellfrontend-popup-prints-image" id={id} key={id} style={background}/>
        }
 
        const addToCartButtonProps = {
            className: "xsellfrontend-prints-popup-add-to-cart-button"                  
        }

        return (
            <Portal>
                <Wrapper guid={guid} config={config} togglePopUp={() => togglePopUp()} >
                    <div className="xsellfrontend-popup xsellfrontend-popup-prints">
                        <div className="xsellfrontend-popup-prints-container">
                            <div className="xsellfrontend-popup-prints-title">
                                <span className="xsellfrontend-popup-prints-title-bold">{product.name} </span> 
                                {/* <span>{locale[config.language]["printsPopupText"] }</span> */}  {/* Waiting for translations  */}
                            </div>

                            <div className="xsellfrontend-popup-prints-image-container">
                                <ContainerListView items={items} layout={layout}>
                                    {(item) => {
                                        return setItemElement(item.id, item.url)
                                    }}
                                </ContainerListView>
                            </div>

                            <div className="xsellfrontend-popup-prints-footer">
                                <div data-tam="image_count_on_popup" className="xsellfrontend-popup-prints-footer-title">
                                    {product.productTranslation.description}
                                </div>

                                <div className="xsellfrontend-popup-prints-price-container">
                                    {layoutHelpers.getPrice(product)}
                                    <div>
                                        {product.price.showAsterisk && <a className="xsell-price-description popup"href={product.price.shippingInfoUri} target="_blank">*{product.price.description}</a>}
                                        {!product.price.showAsterisk && <span>{product.price.description}</span>}
                                    </div>
                                </div>

                                <span data-tam="popup_add_to_cart_button">
                                    <AddToCart 
                                        skipComplete={showCheckOnOrderbutton}
                                        addToCartRequest={product.addToCartRequest}
                                        addToCartAction={() => productActions.addToCart(guid, index+1)}
                                        initialButton={(onClick) => <ArcButton {...addToCartButtonProps} onClick={onClick}>{layoutHelpers.getOrderButtonText(config.language, product.articleType, config.identifiers, product.flow)}</ArcButton>}
                                        loadingButton={(onClick) => <ArcButton {...addToCartButtonProps} disabled onClick={onClick}><img src={loaderGreen} className="xsellfrontend-loader-orderbutton" /></ArcButton>}
                                        completeButton={(onClick) => <ArcButton {...addToCartButtonProps} onClick={onClick}>{locale[config.language]["addToBasketButtonTextClicked"]}</ArcButton>}
                                        checkMarkButton={(onClick) => <ArcButton {...addToCartButtonProps} disabled onClick={onClick}><img width="25" height="25" className="xsellfrontend-add-to-cart-checkmark" src={check} /></ArcButton>}
                                    />
                                </span>
                            </div>
                        </div>           
                    </div>  
                </Wrapper>    
            </Portal>
        )
    }
}

export default PopupPrints
