import React, { Component } from 'react'
import { Loader } from "@albelli/arc-3-react/lib/Loader";
import {If} from '../../app/helpers'

class LoadingScreen extends Component {

    constructor(props){
        super(props)
        this.items
        this.state = {
            items: []
        }
        this.createItems = this.createItems.bind(this)
    }
    
    componentDidMount(){
        const { config } = this.props
        if (config.useDefaultLoadingScreen) {
            window.addEventListener("resize", this.createItems);
            this.createItems()            
        }

    }

    componentWillUnmount(){
        const { config } = this.props
        if (config.useDefaultLoadingScreen) {
            window.removeEventListener("resize", this.createItems);
        }
    }

    getLoadingScreen = () => {
        const { config } = this.props
        if(config.template == "homepage-carousel-portrait-arc-3") {
            return  <div id="xsellfrontend-wrapper-container" className="xsellfrontend-loading-wrapper-container">
                        <div className="xsellfrontend-loader-loading-icon-v2"> <Loader container={false} size="extra-large"/> </div>

                        <div className="xsellfrontend-loader-dummy-text-container-v2">
                            <div className="xsellfrontend-loader-dummy-text-1-v2"/>
                            {/* <div className="xsellfrontend-loader-dummy-text-2-v2"/> */}
                        </div>

                        <div className="xsellfrontend-wrapper-loading-container">
                            {this.state.items}
                        </div>
                    </div>
        } else {
            return  <div id="xsellfrontend-wrapper-container" className="xsellfrontend-loading-wrapper-container">
                        <div className="xsellfrontend-loader-loading-icon"> <Loader container={false} size="extra-large"/> </div>
                        <If cond={config.template == 'checkout-portrait'}>
                            <div className="xsellfrontend-loader-dummy-text-container">
                                <div className="xsellfrontend-loader-dummy-text-1"/>
                                <div className="xsellfrontend-loader-dummy-text-2"/>
                            </div>
                        </If>
                        <div className="xsellfrontend-wrapper-loading-container">
                            {this.state.items}
                        </div>
                    </div>
        }
    }

    render(){
        const { config } = this.props
        if (config.useDefaultLoadingScreen) {
            return this.getLoadingScreen()
        } else {
            return (
                <div id="xsellfrontend-wrapper-container" className="xsellfrontend-loading-wrapper-container">
                    <div className="xsellfrontend-loader-loading-icon center"> <Loader container={false} size="extra-large"/> </div>                  
                </div>
            )
        }
        
    } 

    isMobileView() {
        return window.innerWidth <= 600
    }

    createItems(){
        
        let container = document.getElementById("xsellfrontend-wrapper-container")
        const minWidth =  this.props.config.template == "homepage-carousel-portrait-arc-3" ? 230 : 280
        const allowedPhotos = this.isMobileView() && this.props.config.template !== "homepage-carousel-portrait-arc-3" ? 3 : Math.floor( container.offsetWidth / (minWidth + 14) )
        const margin = 7
        let width = Math.floor(container.offsetWidth / allowedPhotos)

        width = !this.isMobileView() ? (width - (margin * 2)) : "100%"

        const items = []

        for (let i = 0; i < allowedPhotos; i++) {

            if(this.props.config.template == "homepage-carousel-portrait-arc-3") {
                items.push( 

                    <div data-tam='xsellfrontend-loading-wrapper'  className="xsellfrontend-wrapper-loading-small" key={i}>
                        <div className="xsellfrontend-wrapper-loading-image-container-small"></div>
                        <div className="xsellfrontend-wrapper-loading-text-simple">
                            <div className="xsellfrontend-wrapper-loading-text-1"/>
                            <div className="xsellfrontend-wrapper-loading-text-3" />
                        </div>
                    </div> 
                )
            } else {
                items.push( 

                    <div data-tam='xsellfrontend-loading-wrapper'  className="xsellfrontend-wrapper-loading" key={i}>
                        <If cond={this.isMobileView()}>
                            <div className="xsellfrontend-wrapper-loading-title-container">
                                <div className="xsellfrontend-wrapper-loading-title-container-text"></div>
                            </div>
                        </If>
    
                        <div className="xsellfrontend-wrapper-loading-image-container"></div>
    
                        <If cond={!this.isMobileView()}>
                            <div className="xsellfrontend-wrapper-loading-carousel">
                                <div className="xsellfrontend-wrapper-loading-carousel-left-button" />
    
                                <div className="xsellfrontend-wrapper-loading-carousel-image" />
                                <div className="xsellfrontend-wrapper-loading-carousel-image" />
                                <div className="xsellfrontend-wrapper-loading-carousel-image" />
    
    
                                <div className="xsellfrontend-wrapper-loading-carousel-right-button" />
    
                                <div className="xsellfrontend-wrapper-loading-text-0" />
                            </div>
                        </If>
    
                        <div className="xsellfrontend-wrapper-loading-text">
                            <If cond={!this.isMobileView()}> <div className="xsellfrontend-wrapper-loading-text-1"/> </If>
                            <div className="xsellfrontend-wrapper-loading-text-2"/>
                            <div className="xsellfrontend-wrapper-loading-text-3" />
    
    
                            <div className="xsellfrontend-wrapper-loading-button-container">
                                <div className="xsellfrontend-wrapper-loading-button"></div>
                            </div>
    
                            <If cond={this.isMobileView()}> <div className="xsellfrontend-wrapper-loading-text-1" /> </If>
                        </div>
                    </div> 
                )
            }

        }

        this.setState({
            items: items
        });

    }        
}


export default LoadingScreen