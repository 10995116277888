import React from 'react'
import locale from '../../app/locale'
import {If} from '../../app/helpers'
import DiscountCodeRibbonsStyle from '../../app/DiscountRibbonsStyle'


const DiscountRibbons = ({index, config, product, cssClass, text, withDiscount, type = "default"}) => {

    let ribbenText = text ? text : "photobookDiscountText"

    return (
        product.price.discountPercent > 0 &&
        <div data-tam='xsellfrontend-discount-ribbon' className={cssClass} style={DiscountCodeRibbonsStyle.getStyle(config.vendorId, product.price.discountPercent)}>
          { !withDiscount && locale[config.language][ribbenText] }
          { withDiscount && locale[config.language][ribbenText].getDiscountText(product.price.discountPercent) }
        </div>
    )    
}

export default DiscountRibbons