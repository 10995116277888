import React from 'react'
import Slider from 'react-slick'
import NextArrow from './NextArrow'
import PrevArrow from './PrevArrow'

const SlickProductCarousel = ({thumbs, loadAllThumbs, shownImages, config}) => {
    
    var settings = {
        centerMode: false,
        accessibility: false,
        slidesToShow: shownImages - 2,
        slidesToScroll: 2,
        focusOnSelect: false,
        variableWidth: true,
        infinite: false,
        swipe: true,
        onSwipe: loadAllThumbs,
        draggable: true,
        prevArrow: <PrevArrow classname="product-carousel"> </PrevArrow>,
        nextArrow: <NextArrow classname="product-carousel"></NextArrow>,
    }
      
    return (
        <div className="xsellfrontend-carousel-container">
          <Slider {...settings}>
            { thumbs }
          </Slider>
        </div>
    );     
}
  
export default SlickProductCarousel