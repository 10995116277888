import React, { Component } from 'react'
import popup from '../../app/popup'
import locale from '../../app/locale'
import DiscountBox from '../app/DiscountBox'
import {If} from '../../app/helpers'
import price from '../../app/layoutHelpers'


const templateHelpers = (Wrapped) => {
    class Helper extends Component {
        constructor(props){
            super(props)
            
            this.overlay = false

            this.state = {
                showPopUp: false
            }
        }

        updateOverlay(display){
            this.overlay = display
        }


        getEditButtonStyle(status, overlay){
            return {
                pointerEvents: status === "complete" ? "none" : "initial", 
                color: overlay ? "#f4633a" : "#4d4343"
            }
        }


        getPriceTextSmall(articleType, language){
            return (articleType === this.props.config.identifiers.prints && this.props.product.flow == "Editor")? locale[language]["pricePrintsDefaultText"]  + ":" : ""
        }


        getDiscountBox(type){
            const { config, product } = this.props

            if(product.projectName){ // TEMP
                return
            }

            return (
                <If cond={product.price.discountCode && !product.price.discountAutoApply}>
                    <DiscountBox 
                        text={locale[config.language]["discountDefaultText"]}
                        price={product.price}
                        type={type}
                        responsive={true}
                    />
                </If>
            )
        }

        togglePopUp() {
            if (!this.state.showPopUp) {
                this.props.productActions.trackOpenPopup(this.props.product, this.props.index+1)
            }
            this.setState({showPopUp: !this.state.showPopUp})            
        }


        render(){
            const newProps = {
                openPopup: () => this.openPopup(),
                updateOverlay: (display) => this.updateOverlay(display),
                overlay: this.overlay,
                getEditButtonStyle: (status, overlay) => this.getEditButtonStyle(status, overlay),
                getPriceTextSmall: (articleType, language) => this.getPriceTextSmall(articleType, language),
                getDiscountBox: (type) => this.getDiscountBox(type),
                showPopUp: this.state.showPopUp,
                togglePopUp: () => this.togglePopUp(),
                ...price
            }
            return (
                <Wrapped {...this.props} helpers={newProps} />
            )
        }
    }
     
    return Helper
}

export default templateHelpers