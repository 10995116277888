import React, { Component } from 'react'

class DefaultWrapper extends Component{
    constructor(props){
        super(props)
        
    }
    
    render(){
        return <>{this.props.children}</>
    }

}

export default DefaultWrapper