import React, { Component } from 'react'
import Slider from 'react-slick'
import PrevArrow from './PrevArrow'
import NextArrow from './NextArrow'

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

class PortraitCarousel extends Component {
    constructor(props){
        super(props)
    }

    render(){
        var settings = {
            centerMode: true,
            accessibility: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: this.props.template == "carousel-portrait" ? false: true,
            variableWidth: true,
            infinite: true,
            dots: this.props.template == "homepage-carousel-portrait-arc-3" ? false : true,
            autoplay: this.props.template == "carousel-portrait" ? true: false,
            autoplaySpeed: 5000,
            pauseOnFocus: true,
            nextArrow: <NextArrow classname="portrait-carousel"/>,
            prevArrow: <PrevArrow classname="portrait-carousel"/>,
            afterChange: index => {
                if(index === (this.props.children.length - 1 ) && this.props.template == "carousel-portrait"){
                    setTimeout(function(){
                        this.slider.slickGoTo(0)
                    }.bind(this), 5000)
                }
            }
        }

        var productStyle = {}
        if(this.props.children.length === 1){
            productStyle = {
                marginLeft: "-19px"
            }
        }

        return (
            <div style={productStyle} className="xsellfrontend-carousel-portrait container">
              <Slider ref={slider => (this.slider = slider)} {...settings}>
                { this.props.children }
              </Slider>
            </div>
        )
    }
    
}
  
export default PortraitCarousel