import helpers from '../app/helpers'
import {defaultRemoteData} from '../requests/requestHelper'

class ProductActions {

    constructor (productStore, requests, config, analytics) {
        this.productStore = productStore
        this.requests = requests
        this.config = config
        this.analytics = analytics

        this._productStoreSubscriber = this._productStoreSubscriber.bind(this)

        this.productStore.subscribe(this._productStoreSubscriber)
        this.initialAnalyticsCallRun = false
    }

    
    loadInitialProductData() {

        const createProduct = p => {
            return {
                data: this._setProductDefaultValues(p),
                guid: helpers.generateGuid()
            }
        }

        this.requests.getProducts("", "", this.config.inputImageList, (remoteData) => {
            
            this.productStore.setProducts({...remoteData, data: remoteData.data ? remoteData.data.productItemList.map(createProduct) : null})
            this.productStore.setAbTestData({...remoteData, data: remoteData.data ? remoteData.data.abTestData : null})
            this.productStore.setMarketingText({...remoteData, data: remoteData.data ? remoteData.data.marketingText : null})

            if (remoteData.status === "complete") {
                if (this.productStore.data.products.data) {
                    
                    //Get carousel products for all of the products loaded
                    this.productStore.data.products.data.forEach((product) => {
                        if (product.data.usedImageList && product.data.articleType !== "Prints") {
                            this.updateCarouselProducts(product.guid, 4)
                        }                    
                    })
                    
                }
                this.productStore.setShowAllProducts(remoteData.data.showAllProducts)               
            }
            if (remoteData.status === "error") {
                this.productStore.setError(remoteData.error)
            }
        })
    }

    changeProduct(guid, product) {
        const originalProduct = this.productStore.getProductByGuid(guid)
        product = this._setProductDefaultValues(product)
        product.carouselImageList = originalProduct.carouselImageList
        product.carouselProducts = originalProduct.carouselProducts
        this.productStore.changeProduct(guid, product)        
    }

    changeFormatGroup(guid, newFormatGroup) {
        this.productStore.setLoadingStatus(true)
        const product = this.productStore.getProductByGuid(guid)
        const editorData = this.productStore.getEditorData(product.usedImageList[0].imageId, product.articleType)
        const inputImage = editorData !== undefined ? editorData.inputImageList : product.usedImageList[0]
        const inputImageList = inputImage ? [inputImage] : []
        this.requests.getProducts(product.articleType, newFormatGroup, inputImageList, (remoteData) => {
            if (remoteData.status === "complete" && remoteData.data) {
                if (remoteData.data.productItemList.length > 0) {
                    this.productStore.changeProduct(guid, this._setProductDefaultValues(remoteData.data.productItemList[0]))
                    this.updateCarouselProducts(guid, this.config.maxImagesInSmallCarousel)
                } 
                this.productStore.setLoadingStatus(false)
            }
            if (remoteData.status === "error") {
                this.productStore.setError(remoteData.error)
                this.productStore.setLoadingStatus(false)
            }
        })
    }

    editProduct(data, formatGroup, articleType, guid, usedImage, editData) {
        this.productStore.setLoadingStatus(true)
        var inputImageList = {
            imageId: data.imageId,
            thumbnailUrl: data.thumbUrl,
            visibleHeight: data.visibleHeight,
            visibleWidth: data.visibleWidth,
            source: "ORGANISE",
            imageEditServerFilter: data.imageEditServerFilter,
            userId: ""
        }
        this.requests.getProducts(articleType, formatGroup, [inputImageList], (remoteData) => {
            if (remoteData.status === "complete" && remoteData.data) {
                if (remoteData.data.productItemList.length > 0) {
                    this.productStore.changeProduct(guid, this._setProductDefaultValues(remoteData.data.productItemList[0]))
                    this.updateCarouselProducts(guid, this.config.maxImagesInSmallCarousel)
                    this.productStore.setEditorData(usedImage, editData, remoteData.data.productItemList[0].articleType, inputImageList)
                } 
                this.productStore.setLoadingStatus(false)
            }
            if (remoteData.status === "error") {
                this.productStore.setError(remoteData.error)
                this.productStore.setLoadingStatus(false)
            }
        })
    }

    trackOpenPopup(product, position) {
        this.analytics.populateDetails(product, this.config, position)
    }

  
    updateCarouselProducts = async (guid, maxImages) => {
        
        const product = this.productStore.getProductByGuid(guid)
        const existingCarouselProducts = product.carouselProducts.data ? product.carouselProducts.data : []
        const existingCarouselImages = product.carouselImageList
        const numberOfProductsToAdd = maxImages - existingCarouselProducts.length
        
        if (numberOfProductsToAdd > 0) {
            
            var images = existingCarouselImages.length > 0 ? existingCarouselImages : await this._getCarouselImages(product, this.config.carouselInputImageList)

            images.sort((a,b) => {
                if (a.imageId == product.usedImageList[0].imageId) {
                    return -1
                }
                return 1
            })

            if (existingCarouselImages.length === 0 ) {
                this.productStore.setCarouselImageList(guid, images)
            }  

            if(images.length > 1) {
                
                this.productStore.setCarouselProducts(guid, {...defaultRemoteData, status: "loading"})
                
                var carouselProductRequests = this._getCarouselProductRequests(product, images.slice(existingCarouselProducts.length, existingCarouselProducts.length+numberOfProductsToAdd))

                Promise.all(carouselProductRequests).then(newThumbs => {
                    newThumbs = newThumbs.filter(t => t !== null && t !== undefined)
                    if(newThumbs.length > 0) {
                        this.productStore.setCarouselProducts(guid, {...defaultRemoteData, data: existingCarouselProducts.concat(newThumbs), status: "complete"})   
                    } else {
                        this.productStore.setCarouselProducts(guid, {...defaultRemoteData, status: "complete", data: existingCarouselProducts})
                    }                               
                })
                .catch(this.catchError.bind(this))
            } else {
                this.productStore.setCarouselProducts(guid, {...defaultRemoteData, status: "complete"})
            }
        }
    }

    addToCart(guid, position) {
        const product = this.productStore.getProductByGuid(guid)

        this.requests.addToCart(product, (remoteData) => {
            this.productStore.setAddToCartData(guid, remoteData)
            if (remoteData.status === "error") {
                this.productStore.setError(remoteData.error)
            }
            if (remoteData.status === "complete") {
                if (this.config.addToCartCallback) {
                    this.config.addToCartCallback()
                }      
                
                this.analytics.populateClick(product, this.config, position)
            }
        })
    }

    updateProductInstanceId(guid, productInstanceId) {
        this.productStore.updateProductInstanceId(guid, productInstanceId)
    }

    updateProductSelectedPreview(guid, selectedPreview) {
        this.productStore.updateProductSelectedPreview(guid, selectedPreview)
    }

    log(json = {}) {
        this.requests.log(json)
    }

    _setProductDefaultValues (product) {
        
        const p = {...product}
        if(!product.hasOwnProperty("selectedPreview")) {
            p.selectedPreview = 0
            if (product.articleType == "Calendar") {
                p.selectedPreview = 1
            }
        }

        if (!product.hasOwnProperty("addToCartRequest")) {
            p.addToCartRequest = defaultRemoteData
        }

        if(!product.hasOwnProperty("carouselType")) {
            p.carouselType = "product"
            p.carouselProducts = defaultRemoteData
            p.carouselImageList = []
            if (product.articleType == "Calendar") {
                p.carouselType = "preview"
            }
        }               
        return p
    }    

    _getCarouselProductRequests(product, imageList) {
        return imageList.map((image) => {
            return new Promise((resolve, reject) => {
                const setDataCallback = (remoteData) => {
                    if (remoteData.status === "complete") {
                        if (remoteData.data && remoteData.data.productItemList) {
                            resolve(remoteData.data.productItemList[0])
                        } else {
                            reject("Missing productItemList on carousel product data from api on product " + product.pap)
                        }                        
                    }
                    if (remoteData.status === "error") {
                        if (remoteData.responseCode === 204) {
                            resolve(null)
                        } else {
                            reject(remoteData.error)
                        }                        
                    }
                }
                this.requests.getProducts(product.articleType, product.formatGroupKey, [image], setDataCallback)
            })
        })
    }

    _getCarouselImages(product, carouselInputImageList){
        return new Promise((resolve, reject) => {

            const setDataCallback = (remoteData) => {
                if (remoteData.status === "complete") {
                    resolve(remoteData.data)
                }
                if (remoteData.status === "error") {
                    reject(remoteData.error)
                }
            }

            if (carouselInputImageList.length > 0) {
                this.requests.filterImagesForProduct(product.pap, carouselInputImageList, setDataCallback)
            } else {
                this.requests.getCarouselImageList(product.pap, this.config.backendCarouselAmount, setDataCallback)
            }
        })        
    }

    _productStoreSubscriber() {
        const data = this.productStore.getData()  

        if (data.abTestData.status === 'complete' && data.discountedCopyProducts.status == 'complete' && !this.initialAnalyticsCallRun) {
            //After initial product load, update analytics with ab-test data for both frontend, backend and discountedProduct
            let productList = data.products.data ? data.products.data : []
            productList = data.discountedCopyProducts.data ? [data.discountedCopyProducts.data].concat(productList) : productList

            let abTestDataList = data.abTestData.data ? [data.abTestData.data] : []
            if (data.discountAbTestData.data) {
                abTestDataList = abTestDataList.concat([data.discountAbTestData.data])
            }
            let visitorData = this.requests.getAdobeTargetVisitorData()
            this.analytics.populateAssets(this.config, {products: productList, abTestData: abTestDataList, marketingText: data.marketingText.data}, visitorData.marketingCloudVisitorId);
            this.initialAnalyticsCallRun = true
        }        
    }

    catchError(ex){
        
        this.productStore.setError(ex)        
        
        if (typeof(Storage) !== "undefined") {
            if (sessionStorage.getItem('xsell-session')) {
                throw "Failed to get product carousel from xsell service. Following session id can be used to search the logs: " + sessionStorage.getItem('xsell-session');
            }
        }
    }

    setLayout(layout) {
        this.productStore.setLayout(layout)
    }

    setShowAllProducts(show){
        this.productStore.setShowAllProducts(show)
    }

    getEditorData(imageId, pap) {
        return this.productStore.getEditorData(imageId, pap);
    }

    getLoadingStatus(){
        return this.productStore.getLoadingStatus()
    }
}

export default ProductActions