import React, { Component } from 'react'
import Slider from 'react-slick'
import NextArrow from './NextArrow'
import PrevArrow from './PrevArrow'

class Slick extends Component {

    constructor(props){
        super(props)

        this.state = {
            slideIndex: 0,
            sliderStyle: {display: "inline-block"}
        }        
    }
    
       
    render(){

        var settings = {
            centerMode: false,
            accessibility: false,
            slidesToShow: 4,
            slidesToScroll: 4,
            focusOnSelect: false,
            arrows: false,
            variableWidth: true,
            beforeChange: (current, next) => {
                this.setState({slideIndex: next})
            },
            infinite: false,
            centerMode: false,
            swipe: false,
            draggable: false,
            responsive: [
                {
                    breakpoint: 1300,
                    settings: {
                        centerMode: false,
                        accessibility: false,
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        focusOnSelect: false
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                    centerMode: false,
                    accessibility: false,
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    focusOnSelect: false,
                    lazyLoad: "progressive",
                    variableWidth: true,
                    swipe: true,
                    draggable: true
                }
                }
            ]
        }
        
        
        return (
            <div className="xsellfrontend-carousel-container">
                <Slider ref={slider => (this.slider = slider)} {...settings}>
                    { this.props.thumbs }
                </Slider>
                <input style={this.state.sliderStyle} className="xsellfrontend-carousel-range-slider" type="range" min="0" max={this.getMaxForRange()} value={this.state.slideIndex} step="2" onChange={e => this.slider.slickGoTo(e.target.value)} />
            </div>
        )   
    }


    componentWillReceiveProps(nextProps){
        this.setState({sliderStyle: nextProps.thumbs.length <= 4 ? {display:"none"} : {display:"inline-block"}})
    }


    getMaxForRange(windowWidth = window.innerWidth){
        return windowWidth <= 1300 ? 6 : 8
    }
    
}
  
export default Slick