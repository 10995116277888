import React, { Component } from 'react'
import helpers, { If } from '../../app/helpers'
import Carousel from './Carousel'
import PreviewCarousel from '../app/PreviewCarousel'
import Wrapper from './Wrapper'
import DiscountRibbons from '../app/DiscountRibbons'
import ImageContainer from './ImageContainer'
import ContentBoxTop from './ContentBoxTop'
import ContentBoxBottom from './ContentBoxBottom'
import DiscountRibbonsStore from '../../app/DiscountRibbonsStyle'
import { Portal } from 'react-portal'
// import Editor from '../Editor/Editor'
import LoadingIcon from '../../../graphic/xsellLoaderOrange.svg'

import "../../../css/templates/portrait.scss"
import "../../../css/templates/carouselPortrait.scss"
import "../../../css/templates/landscape.scss"
import "../../../css/colors.scss"
import "../../../css/formatButtons.scss"
import "../../../css/loaders.scss"
import "../../../css/ribbons.scss"
import "../../../css/marketingText.scss"
import "../../../css/rangeSlider.scss"
import "../../../css/popup.scss"
import "../../../css/layout.scss"
import "../../../css/templates/vouchers.scss"
import "../../../css/productCarousel.scss"
import "../../../css/carousel.scss"
import "../../../css/generics.scss"

class Popup extends Component{
 
    constructor(props){
        super(props)
        this.state = {
            showEditor: false
        }
    }

    toggleEditor () {
        this.setState({showEditor: !this.state.showEditor})
    }

    render(){
        const { config, moduleIndex, guid, index, showCarousel, product, togglePopUp, getCarouselImageBoxes, productActions, maxNumberOfProducts, showCheckOnOrderbutton } = this.props
        const translations = product.productTranslation
        return (
            <Portal>
                <Wrapper guid={guid} config={config} togglePopUp={() => togglePopUp()} >
                    <div className="xsellfrontend-popup">
                        <div className="xsellfrontend-popup-image-container">
                            <If cond={this.props.productActions.getLoadingStatus()}> 
                                <div className="xsellfrontend-popup-loader-container">
                                    <img className="xsellfrontend-popup-loader-icon" src={LoadingIcon} />
                                </div>
                            </If>
                            <If cond={this.state.showEditor && product.articleType !== "Prints" && product.carouselType !== "preview"}>
                                {/* <Editor product={product} config={config} closeCallBack={() => this.toggleEditor()} productActions={productActions} guid={guid}></Editor> */}
                            </If>

                            <ImageContainer productActions={productActions} guid={guid} product={product} closeCallBack={() => this.toggleEditor()} translations={translations} />
                            <h3 className="xsellfrontend-moreinfo-headline-mobile" >{ translations.name } </h3>

                            <If cond={showCarousel && product.usedImageList.length > 0 && product.articleType !== "Prints"}>
                                <If cond={product.carouselType == "product"}>
                                    <Carousel 
                                        moduleIndex={moduleIndex} 
                                        index={index} 
                                        update={this.update.bind(this)} 
                                        config={config} 
                                        product={product} 
                                        imagesInCarousel={config.carouselInputImageList.length} 
                                        getCarouselImageBoxes={getCarouselImageBoxes}
                                        productActions={productActions} 
                                        guid={guid}
                                        maxNumberOfProducts={maxNumberOfProducts}
                                        showCarousel={showCarousel}
                                    />
                                </If>

                                <If cond={product.carouselType == "preview"}>
                                    <PreviewCarousel 
                                        showCarousel={showCarousel} 
                                        index={index} 
                                        moduleIndex={moduleIndex} 
                                        config={config} 
                                        product={product} 
                                        productActions={productActions} 
                                        getCarouselImageBoxes={helpers.getCarouselImageBoxes}
                                        thumbCssClass="xsellfrontend-viewmore-images-100"
                                        wrapperCssClass="xsellfrontend-preview-carousel-main-wrapper"
                                        guid={guid}
                                    />
                                </If>
                            </If>
                            <DiscountRibbons type="popup" cssClass="xsellfrontend-discount-ribbon-moreinfo" store={DiscountRibbonsStore} index={index} product={product} config={config} />
                        </div>
                        <div className="xsellfrontend-popup-data-container">
                            <ContentBoxTop
                                moduleIndex={moduleIndex}
                                index={index}
                                disable={this.disableFormatbuttons}
                                config={config} 
                                product={product}
                                productActions={productActions}
                                guid={guid}
                                update={this.update.bind(this)}
                                showCarousel={showCarousel}
                                translations={translations}
                                showCheckOnOrderbutton={showCheckOnOrderbutton}
                                // request={request}
                            />
                            <If cond={config.template !== "homepage-carousel-portrait-arc-3"}>
                                <ContentBoxBottom config={config} product={product} translations={translations} />
                            </If>
                        </div>
                    </div>
                        


                </Wrapper>    
            </Portal>
        )
    }

    update(product, image, disable = false){
        const { index, moduleIndex } = this.props
        
        // ProductStore.updateProduct(product, index, moduleIndex)
        // ProductStore.updateUsedPhoto(image, index, moduleIndex)
        // this.disableFormatbuttons = disable
    }

    mapPhoto(image){
        return {
            imageId: image.imageId,
            thumbnailUrl: image.thumbnailUrl || image.thumbnails[1].url,
            visibleHeight: image.visibleHeight,
            visibleWidth: image.visibleWidth,
            source: image.source ? image.source : "ORGANISE",
            imageEditServerFilter: image.imageEditServerFilter ? image.imageEditServerFilter : null,
            userId: image.userId
        }
    }

    getUsedPhoto(props){
        var image = props.config.carouselInputImageList[0]
        if(props.config.inputImageList && props.config.inputImageList.length > 0){
            image = this.mapPhoto(props.config.inputImageList[0])
        }
        return image
    }


}

export default Popup
