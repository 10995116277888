import React from 'react'

const TextContainerSubHeader = ({price, priceTextSmall, type}) => {
    return (
        <div data-tam='xsellfrontend-subheader' className={"xsellfrontend-" + type + "-subheader"}>
            <span>{priceTextSmall}</span>
            <span className={"xsellfrontend-" + type + "-price xsellfrontend-primary-color"}>
                <span>{price}</span>
            </span>
        </div>
    )
}

export default TextContainerSubHeader