import React, { Component } from 'react'
import locale from '../../../app/locale'
import {If} from '../../../app/helpers'
import ImageContainerSmall from '../../app/ImageContainerSmall'
import DiscountRibbons from '../../app/DiscountRibbons'
import DiscountRibbonsStore from '../../../app/DiscountRibbonsStyle'
import Button from '../../app/Button'
import templateHelpers from '../templateHelpers'
import Popup from '../../popup/Popup'

@templateHelpers
class PortraitSimple extends Component{
    constructor(props){
        super(props)
    }

    isMobileView() {
        return window.innerWidth <= 600
    }

    
    render(){
        const { config, product, productStyle, helpers, moduleIndex, index, guid, productActions, hideAllCarousels, showCheckOnOrderbutton, dataTamProductType } = this.props
        let addTocartStatus = product.addToCartRequest.status

        return (
            <div data-tam='xsellfrontend-wrapper' data-tam-product-type={dataTamProductType} style={productStyle} className={"xsellfrontend-simple-portrait-wrapper"}>    

                <DiscountRibbons index={index} store={DiscountRibbonsStore} product={product} config={config} text="discount" cssClass="xsellfrontend-discount-ribbon-simple" />

                <ImageContainerSmall 
                    updateOverlay={helpers.updateOverlay} 
                    overlay={helpers.overlay}
                    product={product} 
                    togglePopUp={() => helpers.togglePopUp()} 
                    status={addTocartStatus} 
                    language={config.language}
                    cssClass={"xsellfrontend-simple-portrait-image-container"}
                />

                {helpers.showPopUp &&  <Popup 
                    config={config} 
                    showCarousel={false}
                    guid={guid}
                    product={product}
                    index={index}
                    moduleIndex={moduleIndex}
                    togglePopUp={() => helpers.togglePopUp()} 
                    getCarouselImageBoxes={helpers.getCarouselImageBoxes}
                    productActions={productActions} 
                    maxNumberOfProducts={config.maxImagesInSmallCarousel}
                    showCheckOnOrderbutton={showCheckOnOrderbutton}
                /> }
                             
                <div onClick={() => helpers.togglePopUp()} className={"xsellfrontend-simple-portrait-text-container"} data-tam="xsell_products">
                 
                    <span className="xsellfrontend-simple-portrait-left-text-container-header" data-tam='xsellfrontend-text-header'>
                            <div>
                                <span data-tam='xsellfrontend-product-name'> {product.productTranslation.name} </span> 
                            </div>
                    </span>

                    <div className={"xsellfrontend-simple-portrait-left-subheader"}  data-tam='xsellfrontend-subheader'>
                        <span>{helpers.getPriceTextSmall(product.articleType, config.language)}</span>
                        <span className={product.price.discountPercent > 0 ? "xsellfrontend-simple-portrait-left-price xsellfrontend-primary-color" : "xsellfrontend-simple-portrait-left-price xsellfrontend-nodiscount-color"}>
                            <span>{helpers.getPriceLeft(product)}</span>
                        </span>
                    </div>
                    
                </div>

                {helpers.getDiscountBox("portrait")}

            </div>
        )
    }

}

export default PortraitSimple