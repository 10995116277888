import React, { Component } from 'react'
import ProductThumb from './Thumb'
import helpers, { If } from '../../app/helpers'
import SlickProductCarousel from '../slick/ProductCarousel'

class ProductCarousel extends Component{

    constructor(props){
        super(props)

        this.initialShownImages = props.shownImages || 4
        this.cssClass = props.wrapperCssClass || "xsellfrontend-carousel-main-wrapper-small"
    }

    getLoadingThumbs() {
        return this.props.getCarouselImageBoxes(
            this.props.maxNumberOfProducts,
            () => [<ProductThumb key={helpers.generateGuid()} />],
            this.props.config.maxImagesInCarousel
        )
    }

    getThumbs(productList) {
       if(productList !== null) {
            const {thumbCssClass} = this.props
            return productList.map((product) => {
                const hasBorder = product.usedImageList?.[0].imageId == this.props.product.usedImageList?.[0].imageId
                const url = product.filteredPreviewImageList[1] ? product.filteredPreviewImageList[1].url : product.filteredPreviewImageList[0].url
                return <ProductThumb key={helpers.generateGuid()} border={hasBorder} updateThumb={this.updateThumb.bind(this, this.props, product)} url={url} size='cover' cssClass={thumbCssClass} />
            })
       }  
    }

    loadAllThumbs(){
        const { productActions, guid, maxNumberOfProducts } = this.props
        setTimeout(() => {
            productActions.updateCarouselProducts(guid, maxNumberOfProducts) 
        }, 100)  //Had to have a delay to avoid the slider going back to start      
    }

    updateThumb(props, product){
        const { productActions, guid } = props
        productActions.changeProduct(guid, product)                       
    }


    render(){
        const { config } = this.props
        const carouselProducts = this.props.product.carouselProducts
        const thumbs = carouselProducts.status === "complete" ? this.getThumbs(carouselProducts.data) : this.getLoadingThumbs()

        return (
            <div className={this.cssClass}>
                <If cond={this.props.showCarousel && thumbs !== undefined }>
                    <div data-tam="xsell-carousel-container" className="xsellfrontend-carousel-wrapper-small slick-wrapper">
                        <SlickProductCarousel config={config} thumbs={thumbs} loadAllThumbs={this.loadAllThumbs.bind(this)} shownImages={this.initialShownImages} />
                    </div>
                </If>
            </div>
        )
    }

}

export default ProductCarousel
