import React, {useState} from 'react'

const AddToCart = ({ skipComplete, addToCartRequest, addToCartAction, initialButton, loadingButton, completeButton, checkMarkButton}) => {

    const [goToCartClicked, setGoToCartClicked] = useState(false)
    
    if (goToCartClicked || (addToCartRequest.status === "complete" && skipComplete)) {
        return checkMarkButton(() => {})
    } else if (addToCartRequest.status === "loading") {
        return loadingButton(() => {})
    } else if (addToCartRequest.status === "complete") {
        if (addToCartRequest.data.hasOwnProperty("cartUrl")) {
            return completeButton(() => {
                setGoToCartClicked(true)
                window.location = addToCartRequest.data.cartUrl
            })            
        } else {
            return completeButton(() => {})
        }    
    } 
    
    return initialButton(() => addToCartAction())
}

export default AddToCart