
var trackAnalytics = function(code){
    if (window['_satellite']) {
        window['_satellite'].track(code)
    }
}

class Analytics {

    constructor(productRequests) {
        this.productRequests = productRequests      
    }

    populateAssets(config, { abTestData, products }, adobeVisitorId){
        window.AA_data = window.AA_data || {}
        window.AA_data["cross-sell-assets"] = products.reduce((assets, p, index) => {
            return assets + this.productData(p.data, config, index+1) + "|"
        }, "").slice(0, -1)
        this.sendTestToAnalytics(abTestData, adobeVisitorId)
                
        trackAnalytics('cross-sell-asset-impression')         
    }


    getAdobeTargetAnalyticUrl(id, adobeVisitorId){
        const reportSuite = ("#{reportSuiteAdobeTarget}".includes("#{") ? "albumprinteralbelliglobaldev" : "#{reportSuiteAdobeTarget}")
        return `https://albumprinter.sc.omtrdc.net/b/ss/${ reportSuite }/0/CODEVERSION?pe=tnt&tnta=${ id }&mid=${ adobeVisitorId }`        
    }


    sendTestToAnalytics(abTestData, adobeVisitorId){
        
        let tntIdList = []
        abTestData.forEach((source) => {
            source.forEach((abTest) => {
                if (abTest.variation.variationId) {
                    tntIdList.push(abTest.variation.variationId)
                }
            })            
        })
        
        if(tntIdList.length > 0 && adobeVisitorId){
            const url = this.getAdobeTargetAnalyticUrl(tntIdList.join(','), adobeVisitorId)
            this.productRequests.notifyAnalyticsWithAdobeTargetTest(url)
        }
    }

    productData(p, config, position) {
        
        const assetId = p.projectName ? "discountedProduct_" + p.pap : p.assetId
        return assetId + "," + config.page + "," + config.aALocation + "," + p.pap + "," + p.price.discountCode+ "," + p.name + "," + p.price.discountPercent + "_off,pos_" + position
    }


    populateDetails(p, config, position){
        window.AA_data["cross-sell-asset"] = this.productData(p, config, position)      
        trackAnalytics('cross-sell-asset-view-details')
    }


    populateClick(p, config, position){
        window.AA_data["cross-sell-asset"] = this.productData(p, config, position)
        trackAnalytics('cross-sell-asset-click')
    }

    trackDiscountedProduct(code, p, config) {
        trackAnalytics(code)
    }
}

export default Analytics

