import React from 'react'
import ReactDOM from 'react-dom'
import userAgent from './js/app/userAgent'
import helpers from "./js/app/helpers"
import Config from './js/app/Config'
import ProductStore from './js/stores/ProductStore'
import ProductActions from './js/actions/ProductActions'
import ProductRequests from './js/requests/ProductRequests'
import App from './js/components/App'


import "./css/templates/portrait.scss"
import "./css/templates/portrait-simple.scss"
import "./css/templates/carouselPortrait.scss"
import "./css/templates/landscape.scss"
import "./css/colors.scss"
import "./css/formatButtons.scss"
import "./css/loaders.scss"
import "./css/ribbons.scss"
import "./css/marketingText.scss"
import "./css/rangeSlider.scss"
import "./css/popup.scss"
import "./css/layout.scss"
import "./css/templates/vouchers.scss"
import "./css/productCarousel.scss"
import "./css/carousel.scss"
import "./css/generics.scss"
import "./css/templates/loading.scss"
import DiscountedCopyProductActions from './js/actions/DiscountedCopyProductActions'
import Analytics from './js/app/analytics';
import validateConfig from './js/app/validate-config'


class XSellFrontend {
    constructor (configuration) {
        this.config = new Config()

        if(configuration.vendorId && configuration.channel && configuration.page && configuration.accessToken){
            this.config.vendorId = configuration.vendorId;
            this.config.channel = configuration.channel;
            this.config.page = configuration.page;
            this.config.inputImageSource = configuration.inputImageSource;
            this.config.device = configuration.device || "auto";
            this.config.inputImageList = configuration.inputImageList;
            this.config.inputProduct = configuration.inputProduct;
            this.config.template = configuration.template;
            this.config.carouselInputImageList = configuration.seeMoreImageList || [];
            this.config.aALocation = configuration.aaLocation || "Photo Organizer";
            this.config.language = configuration.language || "en";
            this.config.productListCallback = configuration.productListCallback;
            this.config.aBTestingCallback = configuration.abTestingCallback;
            this.config.userId = configuration.userId;
            this.config.accessToken = configuration.accessToken;
            this.config.showCarousel = typeof configuration.showCarousel === "undefined"
            this.config.showMobileCarousel = configuration.showMobileCarousel || false
            this.config.oneRowProducts = configuration.oneRowProducts || false
            this.config.numberOfProducts = configuration.numberOfProducts
            this.config.redirectUrl = configuration.redirectUrl || ""
            this.config.maxHeight = configuration.maxHeight || 0
            this.config.loadHeadInCarousel = configuration.loadHeadInCarousel || false
            this.config.addToCartCallback = configuration.addToCartCallback
            this.config.useDefaultLoadingScreen = configuration.useDefaultLoadingScreen || false
            this.config.showOnlyVisibleToYouTag = configuration.showOnlyVisibleToYouTag || false
            this.config.showLoader = configuration.showLoader || true
            this.config.minNumberOfProducts = configuration.minNumberOfProducts || 0

            //Create a unique session for this user
            try {
                if (!sessionStorage.getItem('xsell-session')) {
                    sessionStorage.setItem('xsell-session', helpers.makeid(16));
                }              
            } catch(e) {

            }

            if(this.config.template.includes("arc-3")){
                require("./css/ARC3.scss")
            }
        }
    }

    async injectInto(container) {

        validateConfig(this.config)

        try {
            if(this.config.device === "auto"){ //let xsell deside user agent
                this.config.device = userAgent.get()
            }

            //Setup stores, actions and requests
            const productStore = new ProductStore()
            const productRequests = new ProductRequests(this.config)
            const analytics = new Analytics(productRequests)
           
            const productActions = new ProductActions(productStore, productRequests, this.config, analytics)
            const discountedCopyProductActions = new DiscountedCopyProductActions(productStore, productRequests, this.config, analytics)        

            //Render app        
            this._render(container, productStore, productActions, discountedCopyProductActions, this.config)        

            //Load products
            productActions.loadInitialProductData()
            discountedCopyProductActions.loadDiscountedCopyProduct(this.config.template)
        } catch (error) {
            console.error("Error: ", error)
        }       
        
    }

    _render(container, productStore, productActions, discountedCopyProductActions, config) {
        if (container) {
            //Remove any content inside the container first
            ReactDOM.unmountComponentAtNode(container)

            ReactDOM.render(
                <App productStore={productStore} productActions={productActions} discountedCopyProductActions={discountedCopyProductActions} config={config} containerElement={container} />,
                container
            ) 
        }        
    }
    
}

export {XSellFrontend as construct};
