import React from 'react'

const DiscountBox = ({text, price, type, responsive = false}) => {
    
    let cssClass = "xsellfrontend-standard-" + type + "-discountcode"
    if(!responsive){
        cssClass += "-default"
    }

    return (
        <div className={cssClass}>
            <span>
                {text}:&nbsp;
            </span>
            <span className={"xsellfrontend-standard-" + type + "-discountcode-code"} data-tam="xsell_product_discount_codes">
                {price.discountCode ? price.discountCode : ""}
            </span>
        </div>
    )

}

export default DiscountBox