import React from 'react'

const Button = ({onClickEvent, buttonClass, text, cssClass, style = {}, dataTam = "add_to_cart_button"}) => {    

    let ref = React.createRef()

    return (
        <button ref={ref} style={style} data-testid="button" data-tam={dataTam} onClick={() => { onClickEvent(); ref.current.blur()}} className={cssClass + " " + buttonClass}>
            <span>{text}</span>
        </button>
    )
    
}

export default Button