import { isIE } from './helpers'


const layout = (config, numberOfProducts, moduleIndex, containerWidth, containerHeight, discountedCopyProducts) => {
    
    const getProductsPerRow = width => {
        if(discountedCopyProducts) { // Discounted product = large photobook, and therefor we need to calculate number of products per row based on that.
            containerWidth = containerWidth - 200 // 200 will not work 100%, but it seems to be working for most screen sizes.
        }
        let result = Math.floor( containerWidth / width )
        return result        
    }

    const layoutTypes = {
        "responsive": (i) => {   
            const width = 288
            const margin = 5
            const productsPerRow = getProductsPerRow(width + (margin * 2))       
            return {
                style: {},
                width,
                productsPerRow,
                productStyle: {
                    marginRight: margin,
                    marginLeft: margin,
                    width: width + "px"
                }
            }
        },


        "fixed": (i) => {
            const proportionsBetweenHeightAndWidth = 0.57
            const width = config.maxHeight * proportionsBetweenHeightAndWidth
            const productsPerRow = getProductsPerRow(width)
            const numberOfProducts = productsPerRow > numberOfProducts ? numberOfProducts : productsPerRow
            const marginBottom = 10
            
            if(isIE){
                return {
                    style: { maxHeight: config.maxHeight + marginBottom, marginLeft: "-50px" },
                    productsPerRow,
                    width,
                    productStyle: { maxWidth: width }
                }    
            }

            return {
                style: { maxHeight: config.maxHeight + marginBottom },
                productsPerRow,
                width,
                productStyle: {
                    maxWidth: width + "px",
                    height: config.maxHeight + "px"
                }
            }
        },


        "oneRow": () => {
            var margin = 20
            
            let width = 0
            let minWidth = 492
            let maxWidth = 540
            if(config.template.includes("portrait")){
                minWidth = 280
                maxWidth = 380
            }
            
            let productsPerRow = 0
                
            productsPerRow = getProductsPerRow(minWidth + margin)
            let extraPixels = Math.floor(((containerWidth - (productsPerRow*(minWidth+margin)))/productsPerRow))

            width = minWidth + extraPixels

            if (width > maxWidth) {
                width = maxWidth
            }

            return {
                style: {},
                productsPerRow,
                productStyle: {"width": width + "px"},
                margin: {}
            }
        },


        "mobile": () => {
            const width = containerWidth
            const productsPerRow = getProductsPerRow(width)
            
            return {
                style: {},
                productsPerRow,
                width,
                margin: {
                    marginRight: 7.5,
                    marginLeft: 7.5,
                }
            }
        }
    }
    

    return {
        create(type){
            return layoutTypes[type]
        }
    }

}


export default layout