import React from 'react'
import locale from '../../app/locale'

const ContentBoxBottom = ({product, translations, config}) => {
    
    const toggleSubtitle = (text, label) => {
        return label && label.length > 0 ? "" : text
    }


    const toggleDiscount = (discountAutoApply) => {
        return discountAutoApply ? {display:'none'} : {display:'block'}
    }


    const getDiscountText = (lang, price) => {
        return price.discountCode && !price.discountAutoApply
            ?   <span>{locale[lang]["popupDiscountTextPart1"]} <span className='xsellfrontend-highlight xsellfrontend-primary-color'>{price.discountCode}</span> {locale[lang]["popupDiscountTextPart2"]}</span>
            :   ""
    }


    const drawBullets = (bullets = []) => {
        const checkIcon = config.template.includes("arc-3") 
            ? <i className="arc3-icon arc3-icon--color-brand-cyan xsellfrontend-icon-success-small" style={{float:'left', position:'relative', top: '0px'}} >check</i>
            : <i className='icon form-success small xsellfrontend-icon-success-small' style={{float:'left', position:'relative', top: '3px'}}></i>

        return bullets.split(/\n/g).map((bullet, i) => {
            bullet = bullet.trim()
            if(bullet !== "") {
                return <div key={"bullet" + i} className='xsellfronend-moreinfo-description-bullets-wrapper'>
                {checkIcon}
                <div className='xsellfronend-moreinfo-description-bullets'>{bullet}</div>
                </div>
            }
        })
    }
    

    return (
        <div className="xsellfrontend-moreinfo-contentbox-bottom">
            <div style={toggleDiscount(product.price.discountAutoApply)} className="xsellfrontend-moreinfo-discount-bottom xsellfrontend-moreinfo-discount-bottom-seemore">
                {getDiscountText(translations.lang, product.price)}
            </div>
            <div className="xsellfrontend-moreinfo-description xsellfrontend-moreinfo-description-seemore">
                {drawBullets(translations.bullets)}
            </div>
            <div className="xsellfrontend-moreinfo-ingress">
                {translations.ingress}
            </div>
        </div>
    )
}

export default ContentBoxBottom