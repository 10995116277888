import React, { Component } from 'react'
import locale from '../../../app/locale'
import ImageContainerSmall from '../../app/ImageContainerSmall'
import TextContainerHeader from '../../app/TextContainerHeader'
import DiscountRibbons from '../../app/DiscountRibbons'
import DiscountRibbonsStore from '../../../app/DiscountRibbonsStyle'
import Button from '../../app/Button'
import templateHelpers from '../templateHelpers'
import TextContainerSubHeader from '../../app/TextContainerSubHeader'
import {If} from '../../../app/helpers'
import AddToCart from '../../app/addToCart/AddToCart'
import { Button as ArcButton } from "@albelli/arc-3-react/lib/Button";
import loaderGreen from '../../../../graphic/xsellLoaderGreen.svg'
import check from '../../../../graphic/xsellCheck.png'
import layoutHelpers from '../../../app/layoutHelpers'
import Popup from '../../../components/popup/Popup'


@templateHelpers
class Landscape extends Component{
    
    constructor(props){
        super(props)
        this.showCarousel = this.props.config.showCarousel
        this.overlay = false
    }


    render(){
        const { config, index, product, helpers, moduleIndex, productActions, guid, dataTamProductType, showCheckOnOrderbutton } = this.props
        
        let addTocartStatus = product.addToCartRequest.status

        if(product.hasOwnProperty("carouselProducts") && product.carouselProducts.status == "complete" && !product.carouselProducts.data) {
            this.showCarousel = false
        }

        const addToCartButtonProps = {
            className: "xsellfrontend-standard-landscape-add-to-basket-button"                  
        }

        return  <div data-tam='xsellfrontend-wrapper' data-tam-product-type={dataTamProductType} className='xsellfrontend-standard-landscape-wrapper'>
                    <DiscountRibbons store={DiscountRibbonsStore} index={index} product={product} config={config} cssClass="xsellfrontend-discount-ribbon-landscape" />
                    <ImageContainerSmall 
                        product={product} 
                        status={addTocartStatus} 
                        cssClass="xsellfrontend-standard-landscape-image-container"  
                        cssOverlayStyle={{minHeight:"210px", minWidth:"210px"}} 
                        updateOverlay={helpers.updateOverlay} 
                        overlay={helpers.overlay} 
                        togglePopUp={helpers.togglePopUp}
                        language={config.language} 
                    />

                    <Button 
                        style={helpers.getEditButtonStyle(addTocartStatus, helpers.overlay)}
                        cssClass="xsellfrontend-standard-landscape-bottom-text"
                        buttonClass="small button tertiary"
                        text={locale[config.language]["bottomTextMoreInfo"]}
                        onClickEvent={helpers.togglePopUp}
                        dataTam="xsell_edit_button"
                    /> 


                    {helpers.showPopUp &&  <Popup 
                        config={config} 
                        showCarousel={this.showCarousel}
                        guid={guid}
                        index={index}
                        product={product}
                        moduleIndex={moduleIndex}
                        togglePopUp={helpers.togglePopUp} 
                        getCarouselImageBoxes={helpers.getCarouselImageBoxes}
                        productActions={productActions} 
                        maxNumberOfProducts={config.maxImagesInSmallCarousel}
                        showCheckOnOrderbutton={showCheckOnOrderbutton}
                    /> }  

                    <div className="xsellfrontend-standard-landscape-text-container" data-tam="xsell_products">

                        <TextContainerHeader type="standard-landscape" product={product} />

                        <If cond={product.price.totalPrice > 0}>
                            <TextContainerSubHeader
                                price={helpers.getPrice(product, "xsellfrontend-moreinfo-discount-price-linethrough-landscape")}
                                priceTextSmall={helpers.getPriceTextSmall(product.articleType, config.language)}
                                type="standard-landscape"
                            />
                        </If>
                        
                        {helpers.getDiscountBox("landscape")}

                        <AddToCart 
                            skipComplete={showCheckOnOrderbutton}
                            addToCartRequest={product.addToCartRequest}
                            addToCartAction={() => productActions.addToCart(guid, index+1)}
                            initialButton={(onClick) => <ArcButton data-tam="add_to_cart_button" {...addToCartButtonProps} onClick={onClick}>{layoutHelpers.getOrderButtonText(config.language, product.articleType, config.identifiers, product.flow)}</ArcButton>}
                            loadingButton={(onClick) => <ArcButton data-tam="add_to_cart_button" {...addToCartButtonProps} disabled onClick={onClick}><img src={loaderGreen} className="xsellfrontend-loader-orderbutton" /></ArcButton>}
                            completeButton={(onClick) => <ArcButton data-tam="add_to_cart_button" {...addToCartButtonProps} onClick={onClick}>{locale[config.language]["addToBasketButtonTextClicked"]}</ArcButton>}
                            checkMarkButton={(onClick) => <ArcButton data-tam="add_to_cart_button" {...addToCartButtonProps} disabled onClick={onClick}><img width="25" height="25" className="xsellfrontend-add-to-cart-checkmark" src={check} /></ArcButton>}
                        />
                    </div>
                </div>
    }
}

export default Landscape