class DiscountRibbonsStyle {   
    
    colors = {
        default: "rgb(56, 191, 196)",
        boost:  "rgb(235, 185, 35)",
        flash: "rgb(235, 184, 62)" 
    }

    discount = {
        1200: { default: 20, boost: 25 }, //albelli.nl
        1201: { default: 30, boost: 40 }, //albelli.de
        1203: { default: 20, boost: 25 }, //albelli.fr
        1207: { default: 20, boost: 25 }, //albelli.be
        1213: { default: 25, boost: 30 }, //albelli.se
        1215: { default: 20, boost: 40 }, //fotoknudsen.no
        1221: { default: 30, boost: 40 }, //bonusprint.co.uk
        "default": { default: 20, boost: 30 }  
    }

    getStyle(vendor, percentage) {
        const discounts = this.discount[vendor] || this.discount["default"]
        const color = this.getColor(percentage, discounts.default, discounts.boost)

        return {
            display: percentage > 0 ? "block" : "none",
            backgroundColor:color
        }

    }
        
    getColor(percentage, defaultMax, boostMax){
        var color = this.colors.default

        if(percentage > defaultMax && percentage <= boostMax){ // boost
            this.productsWithBoost++
            color = this.colors.boost
        }

        if(percentage > boostMax){ // flash
            color = this.colors.flash
        }

        return color
    }


    updateAllBannersToBoost(){
        // if(this.productsWithBoost >= 2){
        //     this.discountProducts.forEach((product, i) => {
        //         this.discountProducts[i] = {
        //             backgroundColor: this.colors.boost
        //         }
        //     })
        // } 
    }


    getTextFormat(language){
        return language === "en-GB" ? "reverse" : "default"
    }
}

export default new DiscountRibbonsStyle