const validTemplates = ['homepage-carousel-portrait-arc-3', 'standard-portrait', 'standard-landscape', 'checkout-portrait', 'mypage-portrait', 'mypage-portrait-arc-3', 'carousel-portrait', 'checkout-portrait-voucher']
const validLanguages = ['fr-BE', 'fr-FR', 'sv-SE', 'nl-BE', 'nl-NL', 'de-DE', 'en-GB', 'nb-NO', 'de-AT', 'es-ES', 'pt-PT', 'da-DK', 'it-IT', 'en-IE']


export default function validateConfig(config) {
    
    if (validTemplates.indexOf(config.template) === -1) {
        throw "Invalid xsell-config. Template must be one of the following: " + validTemplates.join(',')
    }
    
    if (validLanguages.indexOf(config.language) === -1) {
        throw "Invalid xsell-config. Language must be one of the following: " + validLanguages.join(',')
    }
    
    if (typeof config.accessToken !== "function") {
        throw "Invalid xsell-config. Accesstoken must be a function"
    }
}