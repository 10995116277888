import React, { Component } from 'react'
import helpers, { If } from '../../app/helpers'
import { Button as ArcButton } from "@albelli/arc-3-react/lib/Button";
import { Dropdown } from "@albelli/arc-3-react/lib/Dropdown"

class Formatbuttons extends Component {
    
    constructor(props){
        super(props)
    }

    render(){
        return (
                <>
                    <If cond={this.props.product.formatLabelList.length <= 1}>
                        <div className="xsellfrontend-moreinfo-buttongroup-wrapper">
                            <div className="xsellfrontend-moreinfo-buttongroup">
                                {this.drawButtons()}
                            </div>
                        </div>
                    </If>
                    <If cond={this.props.product.formatLabelList.length > 1}>
                        <div className="xsellfrontend-moreinfo-dropdown-wrapper arc3-select-dropdown">
                            {this.drawDropdown()}
                        </div>
                    </If>
                </>    
        )
    }


    updateFormat(groupKey){
        const { productActions, guid } = this.props
        
        productActions.changeFormatGroup(guid, groupKey)
    }


    successHandler(usedImage, product){
        const { addToCartStore, update } = this.props
        const newProduct = product[0]
        addToCartStore.setState(this.props, 0)   
        update(newProduct, usedImage)
    }


    drawButtons(){
        const { disable, product } = this.props
        const list = product.formatLabelList || []
        
        return list.map((label, i) => {
           return   !(disable || label.disabled) && <ArcButton secondary={label.formatGroupKey !== product.formatGroupKey} small key={helpers.generateGuid()} onClick={() => this.updateFormat(label.formatGroupKey)} data-tam="xsell_format_buttons">
                                            {label.name}
                                        </ArcButton>
        })
    }

    drawDropdown() {
        const { product } = this.props
        const list = product.formatLabelList || []
        
        return  <Dropdown value={product.formatGroupKey} onChange={(e) => this.updateFormat(e.target.value) }  onChange={(e) => this.updateFormat(e.target.value) }>
                    { list.map((format) => {
                        return !format.disabled && <option value={format.formatGroupKey} key={format.formatGroupKey} > {format.name} </option>
                    })}
                </Dropdown>

        
    }
}

export default Formatbuttons
