import React from 'react'
import locale from '../../app/locale'
import {If} from '../../app/helpers'
import DiscountCodeRibbonsStyle from '../../app/DiscountRibbonsStyle'


const DiscountRibbonsSmall = ({config, product}) => {

    let style = DiscountCodeRibbonsStyle.getStyle(config.vendorId, product.price.discountPercent)
    if(product.price.discountPercent === 0){
        style = { backgroundColor: "#fff" }
    }
    
    return (

        <div className="xsellfrontend-discount-ribbon-container">    
            <div data-tam='xsellfrontend-discount-ribbon' className="xsellfrontend-discount-ribbon-small" style={style}>
                <If cond={DiscountCodeRibbonsStyle.getTextFormat(config.language) === "default" && product.price.discountPercent > 0}>
                    <span>{product.price.discountPercent}% </span> 
                    {locale[config.language]["discountRibbonText"]}
                </If>

                <If cond={DiscountCodeRibbonsStyle.getTextFormat(config.language) !== "default" && product.price.discountPercent > 0}>
                    {locale[config.language]["discountRibbonText"]} 
                    <span> {product.price.discountPercent}</span>%
                </If>
            </div>
        </div>
    )    
}

export default DiscountRibbonsSmall