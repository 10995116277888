import React from 'react'
import { Loader } from "@albelli/arc-3-react/lib/Loader";

const Thumb = ({updateThumb, border, url="", size='25px 25px', pointer='initial', cssClass='xsellfrontend-viewmore-images' }) => {
    let style = {
        backgroundImage: `url(${url})`,
        backgroundPosition: 'center center',
        backgroundSize: size,
        backgroundRepeat: 'no-repeat',
        cursor: 'pointer',
        pointerEvents: pointer,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }

    if(border){
        style = Object.assign(style, { border: '2px solid #f4633a' })
    }

    return (
        <div data-tam="xsell-carousel-thumb" onClick={ updateThumb } style={ style } className={cssClass} >
            { url == "" && <Loader container={false} size="small"/> }
        </div>
    )
}

export default Thumb