class BaseStore {

    eventListeners = []
   
    subscribe(func) {
        this.eventListeners.push(func)
    }

    notify() {
        this.eventListeners.forEach ((func) => {
            func()
        })
    }

    unsubscribe(func) {
        this.eventListeners = this.eventListeners.filter ((i) => { i !== func})
    }   

}

export default BaseStore