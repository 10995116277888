import BaseStore from './BaseStore'
import {defaultRemoteData} from '../requests/requestHelper'

class ProductStore extends BaseStore {

    constructor() {
        super()
        
        this.data = {
            products: defaultRemoteData,
            marketingText: defaultRemoteData,
            abTestData: defaultRemoteData,
            discountAbTestData: defaultRemoteData,
            discountedCopyProducts: {...defaultRemoteData, status: "complete"},
            layout: {
                style: {},
                width: 290,
                productsPerRow: 20,
                margin: {
                    marginRight: 5,
                    marginLeft: 5
                }                
            },
            showAllProducts: false,
            error: "",
            editorData: []
        }
    }

    notify() {
        super.notify()  
    }

    getData() {
        return this.data
    }

    getProductByGuid(guid) {
        if (this.data.products.data) {
            let product = this.data.products.data.find(x => x.guid === guid)
            if (product) {
                return product.data
            }
        }
        return null
    }

    getDiscountedSecondCopyProduct() {
        if (this.data.discountedCopyProducts.data) {
            let product = this.data.discountedCopyProducts
            if (product) {
                return product.data
            }
        }
        return null
    }

    getEditorData(imageId, articleType) {
        return this.data.editorData.find(data => (data.imageId === imageId  && data.articleType === articleType))
    }

    setEditorData(usedImage, editData, articleType, inputImageList) {
        if(this.getEditorData(usedImage.imageId, articleType) == undefined) {
            this.data.editorData.push({
                imageId: usedImage.imageId,
                articleType: articleType,
                thumbnail: usedImage.thumbnailUrl,
                visibleWidth: usedImage.visibleWidth,
                visibleHeight: usedImage.visibleHeight,
                editData: editData,
                inputImageList: inputImageList
            })
        } else {
            var editorData = this.getEditorData(usedImage.imageId, articleType)
            editorData.editData = editData
            editorData.inputImageList = inputImageList
        }
    }

    setProducts(data) {
        this.data = {...this.data, products: data}
        this.notify()
    }    

    setMarketingText(data) {
        this.data = {...this.data, marketingText: data}
        this.notify()
    }

    setAbTestData(data) {
        this.data = {...this.data, abTestData: data}        
        this.notify()
    }

    setDiscountAbTestData(data) {
        this.data = {...this.data, discountAbTestData: data}
        this.notify();
    }

    setDiscountedCopyProducts(data) {
        this.data = {...this.data, discountedCopyProducts: data}
        this.notify()
    }  

    changeProduct(guid, product) {
        const products = this.data.products
        const updatedProducts = {...products}

        updatedProducts.data = updatedProducts.data.map(x => {
            if (x.guid === guid) {
                x.data = product              
            }
            return x
        })

        this.data = {...this.data, products: updatedProducts}
        this.notify()
    }

    setError(message) {
        this.data = {...this.data, error: message}
        this.notify()
    }

    setCarouselImageList(guid, carouselImageList) {
        const products = this.data.products
        const updatedProducts = {...products}

        updatedProducts.data = updatedProducts.data.map(x => {
            if (x.guid === guid) {
                x.data.carouselImageList = carouselImageList
            }
            return x
        })

        this.data = {...this.data, products: updatedProducts}

        this.notify()
    }

    setAddToCartData(guid, data) {
        const products = this.data.products
        const updatedProducts = {...products}

        updatedProducts.data = updatedProducts.data.map(x => {
            if (x.guid === guid) {
                x.data.addToCartRequest = data
            }
            return x
        })

        this.data = {...this.data, products: updatedProducts}

        this.notify()
    }

    setDiscountedSecondCopyProductAddToCartData(data, p) {
        const products = this.data.discountedCopyProducts
        let updatedProducts = {...products}
        
        if(products.data.data.pap == p.pap) {
            updatedProducts.data.data.addToCartRequest = data
        } else {
            updatedProducts.data.data.copies.find(c => c.pap == p.pap).addToCartRequest = data
        }

        this.data = {...this.data, discountedCopyProducts: updatedProducts}
        this.notify()
    }

    setCarouselProducts(guid, carouselProducts) {
                
        const products = this.data.products
        const updatedProducts = {...products}

        updatedProducts.data = updatedProducts.data.map(x => {
            if (x.guid === guid) {
                x.data.carouselProducts = carouselProducts
            }
            return x
        })

        this.data = {...this.data, products: updatedProducts}

        this.notify()
    }

    updateProductInstanceId(guid, productInstanceId) {
        const products = this.data.products
        const updatedProducts = {...products}

        updatedProducts.data = updatedProducts.data.map(x => {
            if (x.guid === guid) {
                x.data.id = productInstanceId
            }
            return x
        })

        this.data = {...this.data, products: updatedProducts}

        this.notify()

    }

    updateProductSelectedPreview(guid, selectedPreview) {
        const products = this.data.products
        const updatedProducts = {...products}

        updatedProducts.data = updatedProducts.data.map(x => {
            if (x.guid === guid) {
                x.data.selectedPreview = selectedPreview
            }
            return x
        })

        this.data = {...this.data, products: updatedProducts}

        this.notify()
    }
    
    hideAllCarousels() {

        if(this.data.products.status == "complete") {
            let result = this.data.products.data.map((product) => {
                if (product.data.articleType === "Voucher") {
                    return true
                }
                if (product.data.carouselProducts.status === "complete" && !product.data.carouselProducts.data) {
                    return true
                }   
                return false                 
            })
            return result.every(val => val)
        }

        return false
    }

    setShowAllProducts(show){
        this.data = {...this.data, showAllProducts: show}
        this.notify()        
    }

    setLayout(layout) {
        this.data = {...this.data, layout: layout}
        this.notify()
    }
    
    getLoadingStatus(){
        return this.data.loadingStatus
    }
    
    setLoadingStatus(status){
        this.data.loadingStatus = status
        super.notify()  
    }

}

export default ProductStore