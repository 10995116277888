import React, { Component } from 'react'
import ProductFormatButtons from '../../voucher/ProductFormatButtons'
import locale from '../../../app/locale'
import appPopup from '../../../app/popup'
import voucherImage from '../../../../graphic/voucher.jpg'
import {If} from '../../../app/helpers'
import AddToCart from '../../app/addToCart/AddToCart'
import { Button as ArcButton } from "@albelli/arc-3-react/lib/Button";
import loaderGreen from '../../../../graphic/xsellLoaderGreen.svg'
import check from '../../../../graphic/xsellCheck.png'
import layoutHelpers from '../../../app/layoutHelpers'



class Voucher extends Component{
    
    constructor(props){
        super(props)
        
        this.width = {}
        this.showVoucher = true
        this.selectedVoucher = "voucherText"
    }
    
    isMobileView() {
        return window.innerWidth <= 600
    }

    render(){

        const voucher = this.props.product
        const { config, guid, moduleIndex, index, container, productActions, product, dataTamProductType, showCheckOnOrderbutton, productStyle } = this.props

        
        const addToCartButtonProps = {
            secondary: true,
            className: "xsellfrontend-standard-portrait-add-to-basket-button-large"              
        }

        return (
                <div data-tam='xsellfrontend-wrapper' style={productStyle} data-tam-product-type={dataTamProductType} className={"xsellfrontend-voucher-wrapper xsellfrontend-voucher-wrapper-portrait"}>
                    <If cond={this.isMobileView()}>
                        <div className={"xsellfrontend-voucher-headline-portrait"}>
                                {voucher.productTranslation.name}
                        </div> 
                    </If>                    
                    <div className={"xsellfrontend-standard-portrait-image-container xsellfrontend-standard-portrait-image-container-voucher"}>
                        <img className={"xsellfrontend-voucher-image-portrait"} src={voucherImage}/>
                        <div className={"xsellfrontend-voucher-circle-portrait"}>
                            <i className="icon category-thank-you"></i>
                        </div>
                    </div>

                    <div className={"xsellfrontend-standard-portrait-text-container-voucher"}>
                        <div className={"xsellfrontend-standard-portrait-text"}>
                            <If cond={!this.isMobileView()}>
                                <div className={"xsellfrontend-voucher-headline-portrait"}>
                                    {voucher.productTranslation.name}
                                </div>  
                            </If>

                            <div data-tam="xsellfrontend-voucher-ingress" className={"xsellfrontend-voucher-ingress-portrait"}>
                                {voucher.productTranslation.ingress}
                            </div>
                        </div>

                        <If cond={!this.isMobileView()}>
                            <div className={"xsellfrontend-standard-portrait-text-buttons"}>
                                <ProductFormatButtons container={container} updateValue={this.updateProductInstanceId.bind(this)} selectedValue={this.selectedVoucher} productActions={productActions} config={config} moduleIndex={moduleIndex} product={voucher} guid={guid} index={index} cssClass={"xsellfrontend-voucher-buttongroup-portrait"} />   

                                <div className="xsellfrontend-standard-portrait-add-to-basket-button-large-container xsellfrontend-standard-landscape-add-to-basket-button-large-container">
                                    <AddToCart 
                                        skipComplete={showCheckOnOrderbutton}
                                        addToCartRequest={product.addToCartRequest}
                                        addToCartAction={() => productActions.addToCart(guid, index+1)}
                                        initialButton={(onClick) => <ArcButton data-tam="add_to_cart_button" {...addToCartButtonProps} disabled={this.selectedVoucher == "voucherText"} onClick={onClick}>{layoutHelpers.getOrderButtonText(config.language, product.articleType, config.identifiers)}</ArcButton>}
                                        loadingButton={(onClick) => <ArcButton data-tam="add_to_cart_button" {...addToCartButtonProps} disabled onClick={onClick}><img src={loaderGreen} className="xsellfrontend-loader-orderbutton" /></ArcButton>}
                                        completeButton={(onClick) => <ArcButton data-tam="add_to_cart_button" {...addToCartButtonProps} disabled onClick={onClick}>{locale[config.language]["addToBasketButtonTextClicked"]}</ArcButton>}
                                        checkMarkButton={(onClick) => <ArcButton data-tam="add_to_cart_button" {...addToCartButtonProps} disabled onClick={onClick}><img width="25" height="25" className="xsellfrontend-add-to-cart-checkmark" src={check} /></ArcButton>}
                                    />
                                </div>
                            </div>
                        </If>
                    </div>

                    <If cond={this.isMobileView()}>
                        <div className="xsellfronted-standard-portrait-voucher-mobile-actions-container">
                            <ProductFormatButtons container={container} updateValue={this.updateProductInstanceId.bind(this)} selectedValue={this.selectedVoucher} productActions={productActions} config={config} moduleIndex={moduleIndex} product={voucher} guid={guid} index={index} cssClass={"xsellfrontend-voucher-buttongroup-portrait"} />   

                            <div className="xsellfrontend-standard-portrait-add-to-basket-button-large-container xsellfrontend-standard-landscape-add-to-basket-button-large-container">
                                <AddToCart 
                                    skipComplete={showCheckOnOrderbutton}
                                    addToCartRequest={product.addToCartRequest}
                                    addToCartAction={() => productActions.addToCart(guid, index+1)}
                                    initialButton={(onClick) => <ArcButton data-tam="add_to_cart_button" {...addToCartButtonProps} disabled={this.selectedVoucher == "voucherText"} onClick={onClick}>{layoutHelpers.getOrderButtonText(config.language, product.articleType, config.identifiers, product.flow)}</ArcButton>}
                                    loadingButton={(onClick) => <ArcButton data-tam="add_to_cart_button" {...addToCartButtonProps} disabled onClick={onClick}><img src={loaderGreen} className="xsellfrontend-loader-orderbutton" /></ArcButton>}
                                    completeButton={(onClick) => <ArcButton data-tam="add_to_cart_button" {...addToCartButtonProps} disabled onClick={onClick}>{locale[config.language]["addToBasketButtonTextClicked"]}</ArcButton>}
                                    checkMarkButton={(onClick) => <ArcButton data-tam="add_to_cart_button" {...addToCartButtonProps} disabled onClick={onClick}><img width="25" height="25" className="xsellfrontend-add-to-cart-checkmark" src={check} /></ArcButton>}
                                />
                            </div>
                        </div>
                    </If>
                    


                </div>
        )
    }

    updateProductInstanceId(event) {
        this.selectedVoucher = event.target.value
        this.props.productActions.updateProductInstanceId(this.props.guid, event.target.value)        
    }

    



    openInformationBox(voucher, guid){
        appPopup.openVoucherPopup(voucher, guid, "-portrait")
    }

}

export default Voucher