
const helpers = {
    generateGuid(){
        var d = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = (d + Math.random()*16)%16 | 0;
            d = Math.floor(d/16);
            return (c=='x' ? r : (r&0x3|0x8)).toString(16);
        });        
        return uuid;
    },


    makeid(length) {
      if (!length) {
        length = 5;
      }

      var text = "";
      var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (var i=0; i < length; i++ ) {
          text += possible.charAt(Math.floor(Math.random() * possible.length));
      }

      return text;
    },

    getCookie(name) {
      // Split cookie string and get all individual name=value pairs in an array
      var cookieArr = document.cookie.split(";");
      
      // Loop through the array elements
      for(var i = 0; i < cookieArr.length; i++) {
          var cookiePair = cookieArr[i].split("=");
          
          /* Removing whitespace at the beginning of the cookie name
          and compare it with the given string */
          if(name == cookiePair[0].trim()) {
              // Decode the cookie value and return
              return decodeURIComponent(cookiePair[1]);
          }
      }
      
      // Return null if not found
      return "";
  }

}


export default helpers;
export const If = ({cond, children}) => cond ? children : null
export const range = size => Array.from(Array(size).keys())
export const isIE = /MSIE|Trident/.test(window.navigator.userAgent)
